import OrderSummary from '../FoodOrder/OrderSummary'
import PaymentSelector from './PaymentSelector'
import React, { useContext, useEffect, useState } from 'react'
import Tips from './Tips'
import { GlobalContext } from '../../appContext/GlobalContext'
import Seo from '../../components/SEO/Seo'
import DelivPickup from './DelivPickup'
import toast from 'react-hot-toast'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { Button, TextInput } from '../../common'
import { DisBtnIcon, InfoIcon } from '../../components/icon'
import { RxCross2 } from 'react-icons/rx'
import { useTranslation } from 'react-i18next'

const FinishOrder = () => {
  const { t } = useTranslation()
  const { method, setMethod, colorCode, buttonTextColorCode, loading, location, setListPickupAddress, uuid } = useContext(GlobalContext)
  const { setDelivPickupType } = useContext(ProductBusketContext)
  const [selectedTip, setSelectedTip] = useState(0)
  const [delivOrPickup, setDelivOrPickup] = useState('')
  const [promoCode, setPromoCode] = useState('')
  const [promoCodeDiscount, setPromoCodeDiscount] = useState('')
  const [inputBorderColor, setInputBorderColor] = useState('#fff'); // Default border color
  const [inputTextColor, setInputTextColor] = useState('#000'); // Default border color
  const [isError, setIsError] = useState(false); // Default border color

  const handleChangeSelectTip = (tip) => {
    setSelectedTip(tip)
  }

  const handlePromoCodeRemove = ()=>{
    setPromoCode('')
    setPromoCodeDiscount('')
  }

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
    setInputBorderColor('#fff');
    setInputTextColor('#000');
    setIsError(false)
    console.log("promoCode...Upd", event.target.value);
  };

  const fetchLocationsForPickup = async () => {
    try {
      let res = await fetch(`${process.env.REACT_APP_API_URL}/partners/locationsForPickup?ClientId=${location?.PartnerId}`)
      let data = await res.json()
      let tempData = []
      for (let i = 0; i < data.locations.length; i++) {
        tempData.push(data.locations[i].address)
      }
      setListPickupAddress(tempData)
    } catch (error) {
      console.error('Error fetching locations for pickup:', error)
      setInputBorderColor('#FD4D4D');
      setInputTextColor('#FD4D4D');
      toast.error("Error fetching locations for pickup", {
        position: 'bottom-right',
      })
    }
  }

  useEffect(() => {
    if (location?.delivery && !uuid) {
      setDelivOrPickup('delivery')
    } else if (location?.pickup && !location?.delivery && !uuid) {
      setDelivOrPickup('pickup')
    }
    fetchLocationsForPickup()
  }, [])

  useEffect(() => {
    setDelivPickupType(delivOrPickup)
  }, [delivOrPickup])

  const handleButtonClick = async () => {
    console.log("Button clicked! Promo Code:", promoCode);
    // Add your logic here, e.g., applying the promo code.
    try {

      const productResponse = await fetch(`${process.env.REACT_APP_API_URL}/partners/getDiscountWithCode/${location.LocationId}/${promoCode}`);
      const productData = await productResponse.json();
          
      console.log("promoCodeData..Before",productData);
      if(productData.data.error || !productData['success']){
        throw new Error("Promocode invalid");
        
      }
      setPromoCodeDiscount(productData.data)
      toast.success(t('checkout.promo_applied_success'), {
        position: 'bottom-right',
      })
      
    } catch (error) {
      console.log("error..",error);
      toast.error(t('checkout.promo_invalid'), {
        position: 'bottom-right',
      })

      setIsError(true)
      
    }
    

  };

  const [isDiscountPopupVisible,setDiscountPopupVisible] = useState(false)
  return (
    <>
      <div className="p-[10px]">
      <div
      className={`fixed z-50 top-0 left-0 w-full h-full bg-[#00000091] overflow-auto transition-all ${isDiscountPopupVisible
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
        }`}

        onClick={() => setDiscountPopupVisible(false)}
    >
      <div style={{
        backgroundColor: '#fff',
        position: 'absolute',
        bottom: 0,
        borderRadius: '8px 8px 0px 0px'
      }}>
        <span
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '22px 17px 30px 12px',
          alignItems: 'center'
        }}  
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              color: '#130D00'
            }}  
          >
              <img
                      alt="System"
                      src="/assets/images/info-grey.svg"
                      style={{
                        height: '20px',
                        width: '20px',
                        marginRight: '9px',
                        cursor:'pointer'
                      }}
                      />
            <h2>{location.discount_promo_text?location.discount_promo_text:t('checkout.discount_heading')}</h2>

          </div>
          <div style={{
            cursor: 'pointer'
          }}
          
            onClick={() => setDiscountPopupVisible(false)}
          >
            <RxCross2 style={{ color: '#000000' }} />
          </div>

        </span>
        <div
        style={{
          margin: '0px 17px 10px 12px',
          fontSize: '16px',
          color: '#9C9C9C'
        }}
        >
        <div dangerouslySetInnerHTML={{ __html: t('checkout.discount_desc').replace(/!/g, '!<br />') }} />
            {/* {t('checkout.discount_desc')} */}
        </div>
      </div>
    </div>


        <Seo name="Finish Order" />
        {!loading && <PaymentSelector method={method} setMethod={setMethod} colorCode={colorCode} />}
        {console.log(uuid)}
        {!loading &&
          (location?.pickup || location?.delivery) &&
          (!uuid || uuid === null || uuid === 'null' || uuid === undefined || uuid === "undefined") &&
          <DelivPickup
            colorCode={colorCode}
            delivOrPickup={delivOrPickup}
            setDelivOrPickup={setDelivOrPickup}
            isPickupAvail={location?.pickup}
            isDelivAvail={location?.delivery}
          />
        }

        {!loading && <Tips selectedTip={handleChangeSelectTip} colorCode={colorCode} buttonTextColorCode={buttonTextColorCode} />}
        {/* {method === 'online' && (
          
        )} */}
        <div
         className='relative mx-[5px] px-[6px] md:p-[10px] mt-[20px]'
        >
          <span
          style={{
            display: 'flex',
            alignItems: 'center',
            color: {colorCode},
            fontSize: '13px',
            marginBottom: '8px'
          }}  

          
          onClick={() => setDiscountPopupVisible(true)}
          >
                  {/* <img
                      alt="System"
                      src={<InfoIcon color={colorCode}/>}
                      style={{
                        height: '20px',
                        width: '20px',
                        marginRight: '9px',
                        cursor:'pointer'
                      }}
                      

                    /> */}
                    <InfoIcon style={{
                     
                        marginRight: '9px',
                        cursor:'pointer'
                      }} color={colorCode}/>
                    <span
                    style={{
                      marginTop: '1.5px',
                      fontWeight:600,
                      color:`${colorCode}`
                    }}
                    >

                    {location.discount_promo_text?location.discount_promo_text:t('checkout.promoCode')}
                    </span>
          </span>
          <div
          style={{
            display: 'flex'
          }}  
          >
            <div style={{position:'relative',flex:1}}>
            <TextInput
              name="promo_code"
              placeholder={t('checkout.promo_code')}
              value={promoCode}
              onChange={handlePromoCodeChange}

              className={`promoCodeInput ${isError ?'promoCodeError':''}`}
    
            />

            {
              promoCodeDiscount && (
                <img
                    alt="cross"
                    src="/assets/images/discount-cross-btn.svg"
                    style={{
                      height: '20px',
                      width: '20px',
                      position:'absolute',
                      right:'4px',
                      top: '11px',
                      cursor:'pointer'
                    }}
                    onClick={() =>handlePromoCodeRemove()}
                  />

              )
            }


            </div>
         
            
            <Button
              colorCode={colorCode}
              // text={'Apply'}
              icon={ <DisBtnIcon  />}
              variant="icon"
              onClick={handleButtonClick}
              className="promoCodeButton"
              disabled={!promoCode}
            />

          </div>
        </div>
      
      {!loading && <OrderSummary delivOrPickup={delivOrPickup} final method={method} selectedTip={selectedTip} promoCodeDiscount={promoCodeDiscount}/>}
    </div>
    </>
  )
}

export default FinishOrder
