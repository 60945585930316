import React, { useContext, createContext, useEffect, useState } from 'react'
import Header02 from '../components/Header/Header02'
import Footer from '../pages/Landing/Footer'
import DesktopOnlyComponent from '../components/MobileOnlyBlock/MobileOnlyBlock'
import { getProcessingCost } from '../utils/price'
import { formatPrice } from '../utils/number'
import { GlobalContext } from '../appContext/GlobalContext'
import { mergeArrayOfObject, UpdateItems } from '../utils/mergeArray'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'

export const ProductBusketContext = createContext()

const ProductLayout = ({ footer = true, children }) => {
  const navigate = useNavigate()
  const { productId, location, uuid, colorCode, buttonTextColorCode, method, setMethod } = useContext(GlobalContext)
  const [busket, setBusket] = useState([])
  const [isItemUnavailableData, setIsItemUnavailableData] = useState([])
  const [showHeader, setShowHeader] = useState(true)
  const [data, setData] = useState()
  const [product, setProduct] = useState()
  const [loading, setLoading] = useState(true)
  const [showDialog, setShowDialog] = useState(false)
  const [tips, setTips] = useState(0)
  const [autoOrder, setAutoOrder] = useState(true)

  const [isDelivPickup, setIsDelivPickup] = useState(false)
  const [delivPickupType, setDelivPickupType] = useState(null)
  const [deliveryData, setDeliveryData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    postalCode: '',
    street: '',
    city: '',
    county: '',
    country: '',
    details: '',
    long: '',
    lat: '',
    noteForCourier: ''
  })
  const [pickupData, setPickupData] = useState({
    firstName: '',
    lastName: '',
    day: '',
    time: '',
    email: '',
    phone: ''
  })
  const [delivPickupPrice, setDelivPickupPrice] = useState(0)
  const [productDiscount, setProductDiscount] = useState([])
  const [completeMenuData, setCompleteMenuData] = useState([])


  const [invoice, setInvoice] = useState({
    name: '',
    cui: '',
    address: '',
    regNo: '',
    bank: '',
    iban: '',
  })
  useEffect(() => {
    if (loading) {
      try {
        const savedBusketString = window.localStorage.getItem('busket');
        const savedTipsString = window.localStorage.getItem('tips');
        if (typeof savedBusketString === 'string') {
          setBusket(JSON.parse(savedBusketString));
        }
        if (typeof savedTipsString === 'string') {
          setTips(JSON.parse(savedTipsString));
        }
        setLoading(false);
      } catch (e) {
        setBusket([]);
        setTips(0);
        setLoading(false);
      }
    }
  }, [loading, productId, uuid]);
  const getCompleteInvoice = () => {
    // if (!uuid || uuid === null || uuid === undefined) {
    //   setLoading(false)
    //   toast.error('An error has occurred while processing the order. Please try again!')
    //   console.error('UUID is missing. Cannot proceed with the order.');
    //   return;
    // }
    const updatedBusket = busket.flatMap((x, index) => {
      if (x.qty > 1 && x.modifiers.length > 0) {
        const additionalItems = []
        for (let i = 0; i < x.qty; i++) {
          const updatedModifiers = x.modifiers.map(modifier => {
            if (modifier.quantity !== 0 && x.qty !== 0) {
              return { ...modifier, quantity: modifier.quantity / x.qty }
            }
            return modifier
          })
          const newItem = {
            ...x,
            qty: 1,
            modifiers: updatedModifiers
          }
          additionalItems.push(newItem)
        }
        return [...additionalItems]
      }
      return x
    })

    console.log("updatedBusket...",updatedBusket);
    
    const items = updatedBusket.map((x) => ({
      productId: x.item.ID,
      type: 'Product',
      quantity: x.qty,
      modifiers: x.modifiers,
      comment: x.comment,
      tags: x.item.Tags,
      'discountedProductPrice':x.item.discountedProductPrice,
      'Price': x.item.Price,
      ...(x.item.discountedProductPrice ? {
          'productLevelDiscount': (x.item.Price - x.item.discountedProductPrice)
      }:{
        'productLevelDiscount': 0
      }
      )
      
    }))
    if (isDelivPickup) {
      return {
        organizationId: productId,
        order: {
          items
        },
        payment: method,
        processingCost: formatPrice(getProcessingCost(busket))
      }
    } else if (uuid !== null) {
      return {
        organizationId: productId,
        order: {
          tableIds: [uuid],
          items
        },
        payment: method,
        processingCost: formatPrice(getProcessingCost(busket))
      }
    } else {
      navigate('/wrong')
    }
  }

  useEffect(() => {
    if (loading) {
      try {
        const savedBusketString = window.localStorage.getItem('busket')
        const savedTipsString = window.localStorage.getItem('tips')
        if (
          typeof savedBusketString === 'string'
        ) {
          setBusket(JSON.parse(savedBusketString))
        }
        if (typeof savedTipsString === 'string') {
          setTips(JSON.parse(savedTipsString))
        }

        setLoading(false)
      } catch (e) {
        setBusket([])
        setTips(0)
        setLoading(false)
      }
    }
  }, [loading, productId, uuid])


  useEffect(() => {
    if (loading === false) {
      let currentBusket = JSON.parse(window.localStorage.getItem('busket'))

      currentBusket = currentBusket == null ? [] : currentBusket
      currentBusket = mergeArrayOfObject(currentBusket, busket)
      currentBusket = UpdateItems(currentBusket, busket)
      if (currentBusket !== null && currentBusket !== undefined) {
        window.localStorage.setItem('busket', JSON.stringify(currentBusket))
      }
      if (tips !== null && tips !== undefined) {
        window.localStorage.setItem('tips', JSON.stringify(tips))
      }
    }
  }, [busket, tips, loading])

  return (
    <ProductBusketContext.Provider
      value={{
        busket,
        setBusket,
        setShowHeader,
        loading,
        invoice,
        setInvoice,
        getCompleteInvoice,
        data,
        setData,
        product,
        setProduct,
        showDialog,
        setShowDialog,
        setIsItemUnavailableData,
        isItemUnavailableData,
        tips,
        setTips,
        autoOrder,
        setAutoOrder,
        isDelivPickup,
        setIsDelivPickup,
        delivPickupType,
        setDelivPickupType,
        deliveryData,
        setDeliveryData,
        pickupData,
        setPickupData,
        delivPickupPrice,
        setDelivPickupPrice,
        productDiscount,
        setProductDiscount,
        completeMenuData,
        setCompleteMenuData
      }}
    >
      <DesktopOnlyComponent>
        <div className="flex flex-col">
          {showHeader && <Header02 />}
          <main className="xxs:w-full sm:w-full md:container lg:container flex-grow mt-[60px] m-auto">
            {children}
          </main>
          {footer && <Footer showSalad={false} colorCode={colorCode} buttonTextColorCode={buttonTextColorCode} />}
        </div>
      </DesktopOnlyComponent>
    </ProductBusketContext.Provider>
  )
}

export default ProductLayout
