import React, { useState, useEffect, useRef } from 'react'
import {
  ExpressCheckoutElement,
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { useContext } from 'react'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { GlobalContext } from '../../appContext/GlobalContext'
import { getProcessingCost, getTotalCost, getTotalCostLocationBased } from '../../utils/price'
import { Button } from '../../common'
import { toast } from 'react-hot-toast'
import { formatPrice } from '../../utils/number'
import { useNavigate } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { IoIosArrowForward } from 'react-icons/io'
import RectangleButton from '../../common/RectangleButton'
import { RxCross2 } from 'react-icons/rx'
import { useTranslation } from 'react-i18next'
import convertTimeStringToDateISO from '../../utils/convertTimeStringToDateISO'

const configKey = process.env.REACT_APP_PUBLISHABLE_KEY

const CheckoutForm = ({ stripeConnectedAccount,discountAmount,promoCodeDiscount }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const { busket, getCompleteInvoice, tips, delivPickupType, deliveryData, setDeliveryData, pickupData, setPickupData } = useContext(ProductBusketContext)
  const { colorCode, location } = useContext(GlobalContext)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState()
  const [customerName, setCustomerName] = useState('')
  // const productTotal = getTotalCost(busket)
  const productTotal = getTotalCostLocationBased(busket, location?.LocationId)
  const processingCost = getProcessingCost(busket)
  const totalSum = (productTotal['discountedTotalPrice']?productTotal['discountedTotalPrice']:productTotal['result']) + processingCost + tips
  const validEmailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
    setDeliveryData(prev => ({
      ...prev,
      email: e.target.value
    }))

  }

  const handleChangeName = (e) => {
    setCustomerName(e.target.value)
    setDeliveryData(prev => ({
      ...prev,
      firstName: e.target.value
    }))
    setPickupData(prev => ({
      ...prev,
      firstName: e.target.value
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!email.match(validEmailRegex)) {
      toast.error('Email is not valid', {
        position: 'bottom-right',
      })
      setIsLoading(false)
      return
    }

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      toast.error(submitError.message, {
        position: 'bottom-right',
      })
      setIsLoading(false)
      return
    }

    toast.loading('We are processing, please wait a bit..', {
      position: 'bottom-right',
      duration: 2000,
    })

    // Create the PaymentIntent and obtain clientSecret
    const res = await fetch(`${process.env.REACT_APP_API_URL}/order/create/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount: Math.round(parseFloat(totalSum-discountAmount) * 100),
        stripeConnectedAccount: stripeConnectedAccount,
      }),
    })

    const {
      clientSecret,
      paymentIntentId,
      error: paymentIntentError,
    } = await res.json()

    if (paymentIntentError) {
      toast.error(paymentIntentError.message, {
        position: 'bottom-right',
      })
      setIsLoading(false)
      return
    }

    const { error } = await stripe.confirmPayment({
      // `elements` instance used to create the Express Checkout Element
      elements,
      // `clientSecret` from the created PaymentIntent
      clientSecret,
      confirmParams: {
        return_url: 'https://example.com',
        payment_method_data: {
          billing_details: {
            email: email,
            name: customerName,
          },
        },
      },
      redirect: 'if_required',
    })

    if (error) {
      toast.error(error.message, {
        position: 'bottom-right',
      })
      setIsLoading(false)
      return
    } else {
      toast.loading('Almost there..', {
        position: 'bottom-right',
        duration: 2000,
      })
      // const phone = localStorage.getItem('phone') ?? ''
      // Send paymentMethod.id to your server or handle the checkout process
      const dataBody = delivPickupType == 'pickup' ? {
        ...getCompleteInvoice(),
        paymentIntentId,
        email,
        tips: formatPrice(tips),
        discounts_products: productTotal['discountedTotalPrice']>0?(productTotal['result'] - productTotal['discountedTotalPrice']):0,
        discount_using_promo: discountAmount,
        promo_code_applied:promoCodeDiscount,
        stripeConnectedAccount,
        type: "pickup",
        deliveryDate: convertTimeStringToDateISO(`${pickupData.day}, ${pickupData.time}`),
        delivery_phone: pickupData.phone,
        delivery_email: email,
        delivery_firstName: pickupData.firstName.split(' ')[0] ?? '',
        delivery_lastName: pickupData.firstName.split(' ')[1] ?? '',
      }
        : delivPickupType == 'delivery' ? {
          ...getCompleteInvoice(),
          paymentIntentId,
          email,
          tips: formatPrice(tips),
          discounts: (productTotal['result'] - productTotal['discountedTotalPrice']),
          stripeConnectedAccount,
          type: "delivery",
          deliveryAddress: {
            "postalCode": deliveryData.postalCode.toString(),
            "street": deliveryData.street,
            "city": deliveryData.city,
            "county": deliveryData.county,
            "long": deliveryData.long,
            "lat": deliveryData.lat,
            "country": deliveryData.country,
            "isDeliveryAddress": true,
            "isInvoiceAddress": true,
            "details": deliveryData.details
          },
          "noteForCourier": deliveryData.noteForCourier,
          "delivery_firstName": deliveryData.firstName,
          "delivery_lastName": deliveryData.lastName,
          "delivery_phone": deliveryData.phone.toString(),
          "delivery_email": deliveryData.email
        }
          : {
            ...getCompleteInvoice(),
            paymentIntentId,
            email,
            tips: formatPrice(tips),
            discounts_products: productTotal['discountedTotalPrice']>0?(productTotal['result'] - productTotal['discountedTotalPrice']):0,
            stripeConnectedAccount,
            discount_using_promo: discountAmount,
            promo_code_applied:promoCodeDiscount
          }
      fetch(`${process.env.REACT_APP_API_URL}/order/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataBody),
      })
        // .then(async (res) => {
        //   if (res.ok) {
        //     setIsLoading(false)
        //     navigate('/food-ordering/thankYou')
        //   } else {
        //     const paymentResult = await res.json()
        //     setIsLoading(false)
        //     let error = paymentResult.error ?? paymentResult.message
        //     if (error) {
        //       toast.error(error, {
        //         position: 'bottom-right',
        //       })
        //     }
        //     navigate('/food-ordering/payment-failed')
        //   }
        // })
        .then((res) => res.json())
        .then((data) => {
          if(data['success']){
            if (delivPickupType == 'pickup' && (data?.orderNo || data?.paymentIntent?.newInvoiceNumber)) {
              if (data?.orderNo) window.localStorage.setItem("thankyou_code", data.orderNo)
              else if (data?.paymentIntent?.newInvoiceNumber) window.localStorage.setItem("thankyou_code", data?.paymentIntent?.newInvoiceNumber)
            }
            // navigate('/food-ordering/thankYou')
            navigate(
              '/food-ordering/thankYou',
              {
                state: {
                  location: `${location?.LocationId}`,
                  discountAmount: discountAmount,
                  promoCodeDiscount:promoCodeDiscount
                }
              }
            )

          }else{
            throw Error(`Some error occured`)
          }
        }).catch(err => {
          toast.error(err.message, {
            position: 'bottom-right',
          })
          navigate('/food-ordering/payment-failed',
            {
              state: {
                location: `${location?.LocationId}`
              }
            })
        }).finally(() => {
          setIsLoading(false)
        })
    }
  }

  const paymentElementOptions = {
    layout: 'tabs',
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div>
        <h4 className="font-normal mt-[5px] text-[16px]">
          {/* Emailul deținătorului */}
          {t('product.owners_email')}
        </h4>
        <input
          className="font-normal shadow border border-[#F9F9F9] rounded-[18px] text-[16px] bg-white text-black py-2 px-4 transition duration-200 ease-in-out outline-none w-full"
          style={{
            fontFamily: 'sans-serif !important',
          }}
          required
          type="email"
          name="cardHolderEmail"
          placeholder="john.doe@adhocorder.ro"
          onChange={(e) => handleChangeEmail(e)}
        />
      </div>
      <div>
        <h4 className="font-normal mt-[5px] text-[16px]">
          {/* Numele deținătorului */}
          {t('product.holders_name')}
        </h4>
        <input
          className="font-normal shadow border border-[#F9F9F9] mb-[5px] text-[16px] rounded-[18px] bg-white text-black py-2 px-4 transition duration-200 ease-in-out outline-none w-full"
          style={{
            fontFamily: 'sans-serif !important',
          }}
          required
          name="cardHolderName"
          // placeholder="Deținătorul cardului"
          placeholder={t('product.cardHolder')}
          onChange={(e) => handleChangeName(e)}
        />
      </div>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Button
        id="submit"
        colorCode={colorCode}
        className="w-full mt-2 font-medium"
        type="submit"
        text={isLoading ? `${t('product.order_sent')} ...` : t('product.confirm_payment')}
        // text={isLoading ? `Comanda se trimite ...` : 'Confirmă plata'}
        disabled={isLoading || !stripe || !elements}
      />
      <p className="mt-2 text-[14px] sm:text-center font-medium">
        {/* Plată procesată de Stripe pentru Adhoc Solutions SRL - Romania */}
        {t('product.payment_notes')}
      </p>
      <p className="mt-2 text-[14px] sm:text-center font-medium">
        {/* Ai nevoie de asistență?{' '} */}
        {t('product.need_assist')}{' '}
        <a
          className="cursor-pointer text-[14px]"
          style={{ color: colorCode }}
          href="https://adhocorder.ro/help-and-support"
        >
          {/* Contactează-ne aici */}
          {t('product.contact_us')}
        </a>
      </p>
    </form>
  )
}

const CheckoutFormPayPal = ({ amount, currency }) => {
  return (
    <div>
      <div>
        <div className="flex w-full justify-between">
          <p className="mt-7">Pay with</p>
          <div className="flex items-center mt-4 ml-6">
            <RectangleButton
              variant="icon"
              text={`Change`}
              icon={<IoIosArrowForward />}
              className="font-normal bg-[#FFF] border-0 hover:bg-[#FFF]"
            />
          </div>
        </div>
        <div className="mb-4 flex items-center justify-start">
          <img
            src="/assets/images/icons/visa.png"
            alt="VISA"
            className="mr-2"
          />
          <p className="m-0">Visa x-0542</p>
        </div>
      </div>
      <p className="font-semibold">
        {formatPrice(amount)} <span className="uppercase">{currency}</span>
      </p>
      <Button
        className="w-full mt-12"
        type="submit"
        text={`Confirm Payment Method`}
      />
    </div>
  )
}

const ExpressCheckoutPage = ({ stripeConnectedAccount,discountAmount,promoCodeDiscount }) => {
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const { busket, getCompleteInvoice, tips, delivPickupType, deliveryData, pickupData } = useContext(ProductBusketContext)
  const { location } = useContext(GlobalContext)

  // const productTotal = getTotalCost(busket)
  const productTotal = getTotalCostLocationBased(busket, location?.LocationId)
  const processingCost = getProcessingCost(busket)
  const totalSum = (productTotal['discountedTotalPrice']?productTotal['discountedTotalPrice']:productTotal['result']) + processingCost + tips
  const [isLoading, setIsLoading] = useState(false)

  const onConfirm = async (e) => {
    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)
    const { error: submitError } = await elements.submit()
    if (submitError) {
      toast.error(submitError.message, {
        position: 'bottom-right',
      })
      setIsLoading(false)
      return
    }

    // Create the PaymentIntent and obtain clientSecret
    const res = await fetch(`${process.env.REACT_APP_API_URL}/order/create/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount: Math.round(parseFloat(totalSum-discountAmount) * 100),
        stripeConnectedAccount: stripeConnectedAccount,
      }),
    })
    const { clientSecret, paymentIntentId } = await res.json()

    const { error } = await stripe.confirmPayment({
      // `elements` instance used to create the Express Checkout Element
      elements,
      // `clientSecret` from the created PaymentIntent
      clientSecret,
      confirmParams: {
        return_url: 'https://example.com',
      },
      redirect: 'if_required',
    })

    if (error) {
      toast.error(error.message, {
        position: 'bottom-right',
      })
      setIsLoading(false)
      return
    } else {
      const email =
        window.localStorage.getItem('email') ?? e.billingDetails.email ?? ''
      // Send paymentMethod.id to your server or handle the checkout process
      const dataBody = delivPickupType == 'pickup' ? {
        ...getCompleteInvoice(),
        paymentIntentId,
        email,
        tips: formatPrice(tips),
        discounts_products: productTotal['discountedTotalPrice']>0?(productTotal['result'] - productTotal['discountedTotalPrice']):0,
        discount_using_promo: discountAmount,
        promo_code_applied:promoCodeDiscount,
        stripeConnectedAccount,
        type: "pickup",
        deliveryDate: convertTimeStringToDateISO(`${pickupData.day}, ${pickupData.time}`),
        delivery_phone: pickupData.phone,
        delivery_email: email,
        delivery_firstName: pickupData.firstName.split(' ')[0] ?? '',
        delivery_lastName: pickupData.firstName.split(' ')[1] ?? ''
      }
        : delivPickupType == 'delivery' ? {
          ...getCompleteInvoice(),
          paymentIntentId,
          email,
          tips: formatPrice(tips),
          discounts: (productTotal['result'] - productTotal['discountedTotalPrice']),
          stripeConnectedAccount,
          type: "delivery",
          deliveryAddress: {
            "postalCode": deliveryData.postalCode.toString(),
            "street": deliveryData.street,
            "city": deliveryData.city,
            "county": deliveryData.county,
            "long": deliveryData.long,
            "lat": deliveryData.lat,
            "country": deliveryData.country,
            "isDeliveryAddress": true,
            "isInvoiceAddress": true,
            "details": deliveryData.details
          },
          "noteForCourier": deliveryData.noteForCourier,
          "delivery_firstName": deliveryData.firstName,
          "delivery_lastName": deliveryData.lastName,
          "delivery_phone": deliveryData.phone.toString(),
          "delivery_email": deliveryData.email
        }
          : {
            ...getCompleteInvoice(),
            paymentIntentId,
            email,
            discounts_products: productTotal['discountedTotalPrice']>0?(productTotal['result'] - productTotal['discountedTotalPrice']):0,
            tips: formatPrice(tips),
            stripeConnectedAccount,
            discount_using_promo: discountAmount,
            promo_code_applied:promoCodeDiscount
          }
      fetch(`${process.env.REACT_APP_API_URL}/order/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataBody),
      })
        // .then(async (res) => {
        //   if (res.ok) {
        //     setIsLoading(false)
        //     navigate('/food-ordering/thankYou')
        //   } else {
        //     const paymentResult = await res.json()
        //     setIsLoading(false)
        //     let error = paymentResult.error ?? paymentResult.message
        //     if (error) {
        //       toast.error(error, {
        //         position: 'bottom-right',
        //       })
        //     }
        //     navigate('/food-ordering/payment-failed')
        //   }
        // })
        .then((res) => res.json())
        .then((data) => {
          if (delivPickupType == 'pickup' && (data?.orderNo || data?.paymentIntent?.newInvoiceNumber)) {
            if (data?.orderNo) window.localStorage.setItem("thankyou_code", data.orderNo)
            else if (data?.paymentIntent?.newInvoiceNumber) window.localStorage.setItem("thankyou_code", data?.paymentIntent?.newInvoiceNumber)
          }
          navigate(
            '/food-ordering/thankYou',
            {
              state: {
                location: `${location?.LocationId}`,
                  discountAmount: discountAmount,
                  promoCodeDiscount:promoCodeDiscount
              }
            }
          )
        }).catch(err => {
          toast.error(err.message, {
            position: 'bottom-right',
          })
          navigate('/food-ordering/payment-failed',
            {
              state: {
                location: `${location?.LocationId}`
              }
            })
        }).finally(() => {
          setIsLoading(false)
        })
    }
  }

  const onClick = ({ resolve }) => {
    const options = {
      emailRequired: true,
    }
    resolve(options)
  }

  const options = {
    layout: {
      maxRows: 2,
      maxColumns: 2,
      overflow: 'auto',
    },
    buttonTheme: {
      applePay: 'white-outline',
      googlePay: 'white',
    },
    buttonType: {
      googlePay: 'plain',
      applePay: 'plain',
    },
  }

  return (
    <ExpressCheckoutElement
      options={options}
      onConfirm={onConfirm}
      onClick={onClick}
      disabled={isLoading}
    />
  )
}

const Checkout = ({ setIsVisible, isVisible,discountAmount,promoCodeDiscount }) => {
  const { t } = useTranslation()
  const [creditCardMethod, setCreditCardMethod] = useState('Credit Card')
  const { busket, tips } = useContext(ProductBusketContext)
  const { method, renderCheckout, stripeConnectedAccount, location, uuid } = useContext(GlobalContext)
  // const productTotal = getTotalCost(busket)
  const productTotal = getTotalCostLocationBased(busket, location.LocationId)
  const processingCost = method === 'online' ? getProcessingCost(busket) : 0
  const totalSum = tips
    ? (productTotal['discountedTotalPrice']?productTotal['discountedTotalPrice']:productTotal['result']) + processingCost + tips - discountAmount
    : (productTotal['discountedTotalPrice']?productTotal['discountedTotalPrice']:productTotal['result']) + processingCost - discountAmount
  const [isXxxs, setIsXxxs] = useState(false)
  const stripePromise = useRef(null)
  const { i18n } = useTranslation()
  const stripeOptions = { locale: i18n.language }

  useEffect(() => {
    const handleResize = () => {
      setIsXxxs(window.innerWidth < 640 && window.innerWidth > 480)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // if (!uuid) {
  //   return (
  //     <div className="flex justify-center items-center">
  //       <p className="text-red-500">{t('errors.tableIdMissing')}</p>
  //     </div>
  //   );
  // }
  if (stripeConnectedAccount == null || renderCheckout === false) {
    toast.error('We are unable right now to accept online payments', {
      position: 'bottom-right',
    })
    return
  }
  if (
    stripeConnectedAccount &&
    stripeConnectedAccount !== '' &&
    stripeConnectedAccount !== 'undefined'
  )
    stripeOptions.stripeAccount = stripeConnectedAccount
  if (stripePromise.current == null) {
    loadStripe(configKey, stripeOptions)
      .then(res => stripePromise.current = res)
      .catch(error => {
        console.log('error loadStripe:', error.message)
      })

  }

  const rawAmount = Number(formatPrice(totalSum)) * 100

  if (Math.round(rawAmount) < 200) return // don't render if price is less then 2 RON

  const roundedAmount = Math.round(rawAmount)

  const elFonts = [
    { cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:400' },
  ]

  const appearance = {
    theme: 'stripe',
    variables: {
      borderRadius: '24px',
      fontFamily: 'Poppins',
      colorText: '#000000',
      colorTextPlaceholder: '#B0B0B0',
      fontSizeBase: '17px',
      fontWeightNormal: '400',
      spacingUnit: '3px',
    },
  }

  const options = {
    mode: 'payment',
    amount: roundedAmount,
    currency: 'ron',
    fonts: elFonts,
    appearance,
  }
  const handleSelectCreditCardMethod = (creditCardMethod) => {
    setCreditCardMethod(creditCardMethod)
  }

  return (
    <div
      className={`fixed z-50 top-0 left-0 w-full h-full bg-[#00000091] overflow-auto transition-all ${isVisible
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
        }`}
      onClick={() => setIsVisible(false)}
    >
      <div
        className="absolute sm:w-[630px] w-full top-[50%] left-[50%] bg-white shadow-lg border p-[30px] md:p-[65px] md:pr-[95px] flex flex-col rounded-[12px]"
        onClick={(e) => e.stopPropagation()}
        style={{ transform: 'translate(-50%,-35%)' }}
      >
        <div className="flex justify-between items-center mb-2">
          <img
            alt="Stripe Logo"
            src="/assets/images/help-step-6.svg"
            className="w-[100px] h-[48px]"
          />
          <button
            className="absolute right-[10px] bg-[#FFF] transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg rounded-[50%] p-[10px]"
            onClick={() => setIsVisible(false)}
          >
            <RxCross2 style={{ color: '#000000' }} />
          </button>
        </div>

        <div className="flex justify-center items-center flex-col">
          <p className="font-semibold text-[18px]">
            {/* Total de plată */}
            {t('product.total_payment')}
          </p>
          <p className="font-semibold text-xl">
            {formatPrice(totalSum)} {options.currency.toUpperCase()}
            <br />
          </p>
        </div>

        {method === 'online' ? (
          <Elements stripe={stripePromise.current} options={options}>
            {/*<div className="xxs:w-full sm:w-full flex xxs:justify-around sm:justify-center">*/}
            {/*  <Button*/}
            {/*      className={`${creditCardMethod === 'Credit Card' ? 'bg-[#ffb800]' : 'bg-white'} ${isXxxs ? 'xxs:w-[230px]' : 'xxs:w-[187px]'} sm:w-1/2 mt-5`}*/}
            {/*      type="submit" text={`Credit card`} value='Credit Card'*/}
            {/*      onClick={() => handleSelectCreditCardMethod('Credit Card')}/>*/}
            {/*  <Button*/}
            {/*      className={`${creditCardMethod === 'PayPal' ? 'bg-[#ffb800]' : 'bg-white'} ${isXxxs ? 'xxs:w-[230px]' : 'xxs:w-[187px]'} sm:w-1/2 mt-5`}*/}
            {/*      type="submit" text={`PayPal`} value='PayPal'*/}
            {/*      onClick={() => handleSelectCreditCardMethod('PayPal')}/>*/}
            {/*</div>*/}
            {creditCardMethod === 'Credit Card' ? (
              <>
                <ExpressCheckoutPage stripeConnectedAccount={stripeConnectedAccount} discountAmount={discountAmount} promoCodeDiscount={promoCodeDiscount} />
                <CheckoutForm stripeConnectedAccount={stripeConnectedAccount} discountAmount={discountAmount} promoCodeDiscount={promoCodeDiscount} />
              </>
            ) : (
              <CheckoutFormPayPal
                amount={options.amount}
                currency={options.currency}
                discountAmount={discountAmount}
              />
            )}
          </Elements>
        ) : null}
      </div>
    </div>

    // {method === 'online' ? (
    //   <Elements stripe={loadStripe(configKey)} options={options}>
    //     {creditCardMethod === 'Credit Card' ? (
    //       <CheckoutForm currency={options.currency} />
    //     ) : (
    //       <CheckoutFormPayPal
    //         amount={options.amount}
    //         currency={options.currency}
    //       />
    //     )}
    //     <br />
    //     <ExpressCheckoutPage />
    //   </Elements>
    // ) : null}
    //   </div>
    // </div>
  )
}

export default Checkout
