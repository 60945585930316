import React, { useEffect, useMemo, useState } from 'react'
import Counter from '../../components/Counter/Counter'
import { formatPrice } from '../../utils/number'
import useViewportWidth from '../../hooks/useViewportWidth'
import { RxCross2 } from 'react-icons/rx'
import { useContext } from 'react'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { ProductImage } from '../../common'
import { GlobalContext } from '../../appContext/GlobalContext'
import { filterDescription, decodeHtmlEntity } from '../Product/BusketsBox'

const EditIcon = () => {
  return (
    <svg
      width="54"
      height="36"
      viewBox="0 0 54 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <path d="M12 32H21" stroke="#130D00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/> */}
      <path
        d="M16.5 15.5002C16.8978 15.1024 17.4374 14.8789 18 14.8789C18.2786 14.8789 18.5544 14.9338 18.8118 15.0404C19.0692 15.147 19.303 15.3032 19.5 15.5002C19.697 15.6972 19.8532 15.9311 19.9598 16.1884C20.0665 16.4458 20.1213 16.7217 20.1213 17.0002C20.1213 17.2788 20.0665 17.5547 19.9598 17.812C19.8532 18.0694 19.697 18.3032 19.5 18.5002L7 31.0002L3 32.0002L4 28.0002L16.5 15.5002Z"
        stroke="#130D00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* <path d="M30.7808 20.058V22.914H34.1408V24.216H30.7808V27.198H34.5608V28.5H29.1848V18.756H34.5608V20.058H30.7808ZM35.9946 24.608C35.9946 23.8333 36.1532 23.1473 36.4706 22.55C36.7972 21.9527 37.2359 21.4907 37.7866 21.164C38.3466 20.828 38.9672 20.66 39.6486 20.66C40.1526 20.66 40.6472 20.772 41.1326 20.996C41.6272 21.2107 42.0192 21.5 42.3086 21.864V18.14H43.9186V28.5H42.3086V27.338C42.0472 27.7113 41.6832 28.0193 41.2166 28.262C40.7592 28.5047 40.2319 28.626 39.6346 28.626C38.9626 28.626 38.3466 28.458 37.7866 28.122C37.2359 27.7767 36.7972 27.3007 36.4706 26.694C36.1532 26.078 35.9946 25.3827 35.9946 24.608ZM42.3086 24.636C42.3086 24.104 42.1966 23.642 41.9726 23.25C41.7579 22.858 41.4732 22.5593 41.1186 22.354C40.7639 22.1487 40.3812 22.046 39.9706 22.046C39.5599 22.046 39.1772 22.1487 38.8226 22.354C38.4679 22.55 38.1786 22.844 37.9546 23.236C37.7399 23.6187 37.6326 24.076 37.6326 24.608C37.6326 25.14 37.7399 25.6067 37.9546 26.008C38.1786 26.4093 38.4679 26.7173 38.8226 26.932C39.1866 27.1373 39.5692 27.24 39.9706 27.24C40.3812 27.24 40.7639 27.1373 41.1186 26.932C41.4732 26.7267 41.7579 26.428 41.9726 26.036C42.1966 25.6347 42.3086 25.168 42.3086 24.636ZM46.8268 19.764C46.5375 19.764 46.2948 19.666 46.0988 19.47C45.9028 19.274 45.8048 19.0313 45.8048 18.742C45.8048 18.4527 45.9028 18.21 46.0988 18.014C46.2948 17.818 46.5375 17.72 46.8268 17.72C47.1068 17.72 47.3448 17.818 47.5408 18.014C47.7368 18.21 47.8348 18.4527 47.8348 18.742C47.8348 19.0313 47.7368 19.274 47.5408 19.47C47.3448 19.666 47.1068 19.764 46.8268 19.764ZM47.6108 20.786V28.5H46.0148V20.786H47.6108ZM51.5683 22.088V26.358C51.5683 26.6473 51.6336 26.8573 51.7643 26.988C51.9043 27.1093 52.1376 27.17 52.4643 27.17H53.4443V28.5H52.1843C51.4656 28.5 50.9149 28.332 50.5323 27.996C50.1496 27.66 49.9583 27.114 49.9583 26.358V22.088H49.0483V20.786H49.9583V18.868H51.5683V20.786H53.4443V22.088H51.5683Z" fill="#130D00"/> */}
    </svg>
  )
}

const BusketBox = ({
  item,
  qty,
  dynamic = true,
  unit = 'RON',
  modifiers,
  onClick,
}) => {
  const [counter, setCounter] = useState(qty)
  const { busket, setBusket } = useContext(ProductBusketContext)
  const { colorCode, selectedLanguage } = useContext(GlobalContext)
  const viewportWidth = useViewportWidth()
  const isSmallScreen = viewportWidth < 640

  const getProductName = (product, language) => {
    const key = `Name${language}`
    return product[key] || product.Name
  }

  const productName = getProductName(item, selectedLanguage)
  console.log(productName)

  const getDesc = (product, language) => {
    const key = `Description${language}`
    return product[key] || product.Description
  }

  const finalDesc = getDesc(item, selectedLanguage)

  const { longDescription, shortDescription } = filterDescription(
    // item?.Description
    selectedLanguage && finalDesc
  )

  const counterAndPrice = useMemo(() => {
    const handleCounterChange = (v) => {
      // console.log('v', v)
      // console.log('qty', qty)
      // let multiplier = v > qty ? v - qty : v < qty ? qty - v : 1
      // console.log('multiplier', multiplier)
      setCounter(v)
      const existingItemInBasket = busket.findIndex(
        (x) =>
          x.item.ID === item.ID &&
          x.modifiers.every((modifier, index) => modifier === modifiers[index])
      )
      if (existingItemInBasket !== -1) {
        setBusket((prv) =>
          prv.map((x, index) => {
            if (index === existingItemInBasket)
              return {
                ...x,
                modifiers: x.modifiers.map((modifier, i) => ({
                  ...modifier,
                  quantity:
                    v > qty
                      ? modifier.quantity + modifier.quantity / qty
                      : v < qty
                      ? modifier.quantity - modifier.quantity / qty
                      : modifier.quantity,
                })), // WORKED AS MULTIPLIER MODIFIER IF USER MULTIPLY THE PRODUCT, IF IT DELETED THEN THE MODIFIER WILL NOT FOLLOW THE QTY OF MAIN PRODUCT
                qty: v,
              }
            return x
          })
        )
      }
    }

    const modifierPrice = modifiers.reduce(
      (result, modifier) =>
        result +
        (modifier?.quantity
          ? (modifier?.Price || 0) 
          : modifier?.Price || 0),
      0
    )

    return (
      <div
        className={`flex items-center mt-2 mb-4 ${
          dynamic ? 'justify-evenly' : 'justify-end'
        }`}
      >
        <h4
          className={`${
            viewportWidth < 352
              ? 'ml-[53px] text-[16px]'
              : 'ml-[65px] text-[18px]'
          } font-medium w-full sm:ml-[0px]`}
        >
          {/* {formatPrice(item.Price * counter + modifierPrice)} {unit} */}

          {item.discountedProductPrice ? (
                  <>        
                    {formatPrice(item.discountedProductPrice  + modifierPrice)} {unit}
                  </>
                ) : (
                  <>
                   {formatPrice(item.Price  + modifierPrice)} {unit}
                  </>
                )}

        </h4>


        {!dynamic && (
          <h4
            className={`${
              viewportWidth < 352
                ? 'ml-[53px] text-[14px]'
                : 'ml-[81px] text-[18px]'
            } font-normal w-full sm:ml-[0px] text-center`}
          >
            {`x ${qty}`}
          </h4>
        )}
        {dynamic && (
          <Counter
            colorCode={colorCode}
            value={counter}
            onChange={handleCounterChange}
            size={25}
            fontSize={15}
          />
        )}
        {dynamic && (
          <button
            className="cursor-pointer top-[15px] right-[10px] bg-[#FFF] transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg mb-3"
            onClick={onClick}
          >
            <EditIcon />
          </button>
        )}
      </div>
    )
  }, [
    modifiers,
    dynamic,
    viewportWidth,
    item.Price,
    item.ID,
    counter,
    unit,
    qty,
    colorCode,
    onClick,
    busket,
    setBusket,
  ])

  const handleCancel = (e) => {
    e.stopPropagation()
    const existingItem = busket.findIndex((x) => x.item.ID === item.ID)

    if (existingItem !== -1) {
      let newBusket = busket.filter((x, index) => index !== existingItem)
      window.localStorage.setItem('busket', JSON.stringify(newBusket))
      // setBusket((prevBusket) => prevBusket.filter((x, index) => index !== existingItem)
      setBusket(newBusket)
    }
  }

  return (
    <div className="relative p-[5px] shadow-md rounded-[10px] border border-gray-200 my-[5px] w-full ">
      <div className="flex  gap-[15px]">
        <div className="relative w-[66px] h-[63px]">
          <ProductImage
            product={item}
            className="absolute top-0 left-0 w-full h-full object-cover rounded-[10px]"
          />
        </div>
        <div className="flex-1 flex flex-col justify-between  h-full mt-2">
          <h3
            className="font-medium text-[1rem] sm:text-[18px]"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '1',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {window.location.pathname === '/food-ordering/thankYou' &&
              ``}{' '}
            {productName}
          </h3>
          <p
            className="text-[11px] sm:text-[14px] font-normal pt-2"
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '3',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {(!modifiers || modifiers.length === 0) &&
              (shortDescription
                ? decodeHtmlEntity(shortDescription)?.replace(/<[^>]+>/g, '')
                : decodeHtmlEntity(longDescription)?.replace(/<[^>]+>/g, ''))}
            {modifiers &&
              modifiers.map((modItem, index) => {
                if (index < 12) {
                  const modItemQty = modItem?.quantity
                    ? modItem?.quantity / qty
                    : 0
                  const modItemNameFilter = (product, language) => {
                    const key = `Name${language}`
                    return product[key] || product.Name
                  }
                  const modItemName = modItemNameFilter(
                    modItem,
                    selectedLanguage
                  )
                  return (
                    <span>
                      {modItem?.quantity && modItemQty > 1 && `${modItemQty} x`}{' '}
                      {selectedLanguage && modItemName}
                      {index < modifiers.length - 1 && ','}
                      &nbsp;
                    </span>
                  )
                }
              })}
            {modifiers && modifiers.length > 15 && <span>...</span>}
          </p>
          {!isSmallScreen && counterAndPrice}
        </div>
      </div>
      {isSmallScreen && counterAndPrice}
      {dynamic && (
        <button
          className="absolute top-0 right-[10px] bg-[#FFF] transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg rounded-[50%] p-[10px]"
          onClick={handleCancel}
        >
          <RxCross2
            style={{ color: '#130D00', width: '20px', height: '20px' }}
          />
        </button>
      )}
    </div>
  )
}

export default BusketBox
