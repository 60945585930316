import { formatPrice } from '../../utils/number'
import { Button, ProductImage, TextArea } from '../../common'
import React from 'react'
import Counter from '../Counter/Counter'
import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useRef, useState, useMemo } from 'react'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { GlobalContext } from '../../appContext/GlobalContext'
import { toast } from 'react-hot-toast'
import uuid from 'react-uuid'
import CircleCheckBox from '../../common/CircleCheckBox'
import useViewportWidth from '../../hooks/useViewportWidth'
import CheckBoxProductDialog from '../../common/CheckBoxProductDialog'
import DropdownProduct from '../../common/DropdownProduct'
import { RxCross2 } from 'react-icons/rx'
import './ProductDialog.css'
import { filterDescription } from '../../pages/Product/BusketsBox'
import allergenImages from '../../utils/allergenImages'
import { BiInfoCircle } from 'react-icons/bi'
import AllergensPopup from '../AllergensPopup/AllergensPopup'
import parse from 'html-react-parser'

function basketCompare(arr1, arr2, prodQty) {
  if (arr1.length !== arr2.length) {
    return false
  }

  const ids1 = arr1
    .map((item) => ({ ID: item.ID, quantity: item?.quantity }))
    .sort()
  const ids2 = arr2
    .map((item) => ({ ID: item.ID, quantity: item?.quantity }))
    .sort()

  for (let i = 0; i < ids1.length; i++) {
    if (
      ids1[i].ID !== ids2[i].ID ||
      ids1[i].quantity / prodQty !== ids2[i].quantity
    ) {
      return false
    }
  }

  return true
}

const Modifier = ({
  modifier,
  onChange,
  initialValue = [],
  fromFoodOrdering,
  mainCounter,
}) => {
  const [selectedItems, setSelectedItems] = useState(initialValue)
  const [tempSelectedItems, setTempSelectedItems] = useState([])
  const { colorCode, selectedLanguage } = useContext(GlobalContext)
  const isInitialMount = useRef(false)
  const modifierItems = useMemo(() => {
    return [...modifier?.Items]
  }, [modifier?.Items])
  const [modifierItemsLength, setModifierItemsLength] = useState(
    modifierItems?.length || 0
  )
  const useCounter = useRef(modifierItemsLength < modifier?.Max)
  const [selectedItemsCounter, setSelectedItemsCounter] = useState(0)

  useEffect(() => {
    if (fromFoodOrdering) {
      let tempArr = initialValue.map((val, i) => ({
        ...val,
        quantity: val.quantity / mainCounter,
      }))
      setTempSelectedItems(tempArr)
      setSelectedItemsCounter(
        tempArr.reduce(
          (result, modifier) =>
            result + (modifier?.quantity ? modifier?.quantity || 1 : 1),
          0
        )
      )
    }
  }, [])

  const onSelectItem = (checked, item) => {
    let currentSelected = [...selectedItems]
    if (checked) {
      currentSelected.push(item)
    } else {
      if (selectedItems.length - 1 < modifier?.Min) return
      currentSelected = currentSelected?.filter((modfy) => modfy.ID !== item.ID)
    }
    setSelectedItems(currentSelected)
    onChange(modifier?.ID, currentSelected)
  }

  const onSelectRadioItem = (item) => {
    const isChecked = selectedItems.some((elmnt) => elmnt?.ID === item?.ID)
    if (isChecked) {
      setSelectedItems([])
      onChange(modifier?.ID, [])
      return
    }

    setSelectedItems([item])
    onChange(modifier?.ID, [item])
  }

  const handleCounterChange = (v, item, type) => {
    let currentSelected = fromFoodOrdering
      ? [...tempSelectedItems]
      : [...selectedItems]
    let currentItemIndex = currentSelected.findIndex((x) => x.ID === item.ID)
    if (type == 'plus') {
      if (selectedItemsCounter >= modifier?.Max) return
    } else {
      if (selectedItemsCounter - 1 < modifier?.Min) return // FOR PREVENT USER SELECT MODIFIER LESS THAN THE MINIMUM
      // item.quantity = v
      // currentSelected = currentSelected?.filter((modfy) => modfy.ID !== item.ID)
    }
    if (currentItemIndex !== -1) {
      if (type == 'minus' && v == 0) {
        currentSelected.splice(currentItemIndex, 1)
      } else {
        currentSelected[currentItemIndex].quantity = v
      }
    } else {
      item.quantity = v
      currentSelected.push(item)
    }

    setSelectedItemsCounter(
      currentSelected.reduce(
        // (result, modifier) => result + (modifier?.quantity ? (modifier?.quantity || 1) : 1),
        (result, modifier) => result + modifier?.quantity,
        0
      )
    )
    if (fromFoodOrdering) {
      setTempSelectedItems(currentSelected)
    } else {
      setSelectedItems(currentSelected)
    }
    onChange(modifier?.ID, currentSelected)
  }

  const counterValue = (item) => {
    let selectedItem = fromFoodOrdering
      ? tempSelectedItems.find((elmnt) => elmnt?.ID === item?.ID)
      : selectedItems.find((elmnt) => elmnt?.ID === item?.ID)
    if (!selectedItem) {
      return 0
    } else {
      if (!selectedItem.quantity) {
        let currentSelected = fromFoodOrdering
          ? [...tempSelectedItems]
          : [...selectedItems]
        let currentItemIndex = currentSelected.findIndex(
          (x) => x.ID === item.ID
        )
        if (currentItemIndex !== -1) {
          currentSelected[currentItemIndex].quantity = 1
        } else {
          item.quantity = 1
          currentSelected.push(item)
        }
        setSelectedItemsCounter(
          currentSelected.reduce(
            (result, modifier) =>
              result + (modifier?.quantity ? modifier?.quantity || 1 : 1),
            0
          )
        )
        setSelectedItems(currentSelected)
        onChange(modifier?.ID, currentSelected)
      }
      // handleCounterChange(1, item)
      return selectedItem.quantity ?? 1
    }
  }

  useEffect(() => {
    const setDefaultModifiers = () => {
      let results = []
      const sortedByPriceItems = [...modifierItems]?.sort((a, b) => {
        return a.Price - b.Price
        // return a?.Price > b?.Price ? 1 : -1
      })
      for (let i = 0; i < modifier?.Min; i++) {
        results?.push(modifierItems[i])
      }
      setSelectedItems(results)
      onChange(modifier?.ID, results)
    }
    // run only once
    if (!fromFoodOrdering && !isInitialMount.current && modifier?.Min > 0) {
      isInitialMount.current = true
      setDefaultModifiers()
    }
  }, [modifier, modifierItems, onChange])

  const getModifierName = (modifier, language) => {
    const key = `Name${language}`
    return modifier[key] || modifier.Name
  }

  const modifierName = getModifierName(modifier, selectedLanguage)

  return (
    <div className="flex flex-col gap-[5px] py-[0]">
      <h3 className="font-bold text-[18px]">{modifierName}</h3>

      <p className="text-[14px] font-medium">
        {/* <h3 className="font-bold text-[18px]">{modifier?.Name}</h3> */}
        {/* <p className="text-[14px] font-medium"> */}
        Selectează între {modifier?.Min} și {modifier?.Max} opțiuni
      </p>
      {modifierItems?.map((item, index) => {
        const getProductName = (item, language) => {
          const key = `Name${language}`
          return item[key] || item.Name
        }

        const productName = getProductName(item, selectedLanguage)
        return (
          <div key={index}>
            {!useCounter.current && modifier?.Max > 1 && (
              <button
                className="flex gap-[10px] items-center w-full"
                onClick={() => {
                  const isItemDisabled =
                    selectedItems.find((elmnt) => elmnt?.ID === item?.ID) ===
                      undefined && selectedItems.length >= modifier?.Max

                  if (!isItemDisabled) {
                    onSelectItem(
                      !selectedItems.find((elmnt) => elmnt?.ID === item?.ID),
                      item
                    )
                  }
                }}
              >
                <CheckBoxProductDialog
                  colorCode={colorCode}
                  checked={
                    selectedItems.find((elmnt) => elmnt?.ID === item?.ID) !==
                    undefined
                  }
                  onChange={(checked) => onSelectItem(checked, item)}
                  disabled={
                    (selectedItems.find((elmnt) => elmnt?.ID === item?.ID) ===
                      undefined &&
                      selectedItems.length >= modifier?.Max) ||
                    selectedItems.length - 1 < modifier?.Min
                  }
                />
                <div className="flex flex-1 justify-between w-full">
                  <span className="text-left">{productName}</span>
                  <span className="font-bold min-w-[74px]">
                    {item?.Price.toFixed(2)} <span>RON</span>
                  </span>
                </div>
              </button>
            )}
            {useCounter.current && modifier?.Max > 1 && (
              <div className="flex flex-1 justify-between items-center w-full">
                <span className="w-[10rem] text-left font-medium">
                  {item?.Name}
                </span>
                <div className="">
                  <Counter
                    colorCode={colorCode}
                    className=""
                    fontSize={1.3}
                    value={counterValue(item)}
                    onChange={(value, type) =>
                      handleCounterChange(value, item, type)
                    }
                    min={0}
                    max={modifier?.Max}
                    // disabled={ selectedItemsCounter >= modifier?.Max }
                    mxSize={5}
                  />
                </div>
                <span
                  className="font-semibold w-[fit-content]"
                  style={{ alignSelf: 'center' }}
                >
                  {item?.Price.toFixed(2)} <span>RON</span>
                </span>
              </div>
            )}
            {modifier?.Max <= 1 && (
              <button
                className="flex gap-[10px] items-center w-full"
                onClick={(checked) => onSelectRadioItem(item, checked)}
              >
                <CircleCheckBox
                  colorCode={colorCode}
                  checked={
                    selectedItems.find((elmnt) => elmnt?.ID === item?.ID) !==
                    undefined
                  }
                  onChange={(checked) => onSelectRadioItem(item, checked)}
                  disabled={
                    selectedItems.find((elmnt) => elmnt?.ID === item?.ID) ===
                      undefined && selectedItems.length >= modifier?.Max
                  }
                />
                <div className="flex flex-1 text-left justify-between items-center w-full">
                  {/* <span className="flex-1 flex">{languages.en ? item?.NameEN : languages.de ? item?.NameDE : item?.Name}</span> */}
                  <span className="flex-1 flex">{productName}</span>
                  <span className="font-bold w-[fit-content]">
                    {item?.Price.toFixed(2)} <span>RON</span>
                  </span>
                </div>
              </button>
            )}
          </div>
        )
      })}
      <span
        className={`w-full [border-bottom:3px_solid] my-4`}
        style={{ borderColor: colorCode }}
      />
    </div>
  )
}

const ProductDescription = ({ description }) => {
  if (description === undefined || description === null) return

  return (
    <div
      style={{
        overflowWrap: 'anywhere',
      }}
    >
      {parse(description)}
    </div>
  )
}

const ProductDialog = ({
  customerAvailabilityStatus,
  show,
  setShow,
  product,
  unit = 'RON',
  nutritionalValues,
  basketId,
  selectedModifiers,
  fromFoodOrdering = false,
  qty,
}) => {
  const { t } = useTranslation()
  // const [counter, setCounter] = useState(1)
  // const [comment, setComment] = useState('')
  // const [modifiers, setModifiers] = useState([])
  const [tags, setTags] = useState([])
  const { busket, setBusket } = useContext(ProductBusketContext)
  const [temporaryBusket, setTemporaryBusket] = useState()
  const [prodDesc, setProdDesc] = useState()
  const basketItem = busket?.find((busketItem) => busketItem?.id === basketId)
  const [counter, setCounter] = useState(
    !isNaN(basketItem?.qty) ? basketItem.qty : 1
  )
  const [comment, setComment] = useState(
    basketItem?.comment ? basketItem.comment : ''
  )
  const {
    colorCode,
    buttonTextColorCode,
    selectedLanguage,
    autoOrder,
    location,
  } = useContext(GlobalContext)
  const [modifiers, setModifiers] = useState(() => {
    return basketItem
      ? product?.Modifiers?.reduce((res, productModifier) => {
          const selectedModifiers = productModifier.Items.filter(({ ID }) =>
            basketItem.modifiers.some(
              (basketModifier) =>
                productModifier.ID === basketModifier.parentId &&
                basketModifier?.ID === ID
            )
          ).map((item) => ({ ...item, parentId: productModifier.ID }))
          if (!selectedModifiers?.length) {
            return res
          }
          selectedModifiers.forEach((modifierObj) => {
            let existingBusketModifier = basketItem.modifiers.filter(
              ({ ID }) => modifierObj.ID === ID
            )
            if (existingBusketModifier?.length === 1) {
              modifierObj.quantity = existingBusketModifier[0].quantity ?? 0
            }
          })
          res[productModifier.ID] = selectedModifiers
          return res
        }, {})
      : product?.Modifiers?.reduce?.((res, modifier) => {
          let results = []
          const sortedByPriceItems = [...modifier?.Items]?.sort((a, b) => {
            return a.Price - b.Price
            // return a?.Price > b?.Price ? 1 : -1
          })
          for (let i = 0; i < modifier?.Min; i++) {
            results?.push({ ...sortedByPriceItems[i], parentId: modifier.ID })
          }

          res[modifier.ID] = results
          return res
        }, {}) || {}
  })

  const [widthClass, setWidthClass] = useState('w-[fit-content]')
  const containerRef = useRef(null)
  const h3Ref = useRef(null)
  const h4Ref = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const viewportWidth = useViewportWidth()

  const getProductName = (product, language) => {
    const key = `Name${language}`
    return product[key] || product.Name
  }

  const productName = getProductName(product, selectedLanguage)

  const getDesc = (product, language) => {
    const key = `Description${language}`
    return product[key] || product.Description
  }

  const finalDesc = getDesc(product, selectedLanguage)

  const { longDescription, shortDescription } = filterDescription(finalDesc)

  const [allergens, setAllergens] = useState()
  const [totalModifiersSum, setTotalModifiersSum] = useState(0.0)
  const [outOfStockHeight, setOutOfStockHeight] = useState()
  const [showDropDown, setshowDropDown] = useState(false)

  const handleAllergensOpen = (e) => {
    e.stopPropagation()
    setIsVisible(true)
  }

  useEffect(() => {
    if (selectedLanguage) {
      setProdDesc(finalDesc)
    }
  }, [
    selectedLanguage,
    product?.Description,
    product?.DescriptionEN,
    product.ID,
  ])

  useEffect(() => {
    if (!product?.ID) return

    fetch(
      `${process.env.REACT_APP_API_URL}/partners/locations/menu/nutr_values_calc?ProductId=${product?.ID}`
    )
      .then((res) => res.json())
      .then((data) => {
        let potentialAllergens = data?.ingredients[0]?.potential_allergens
        if (
          (data?.ingredients[0]?.allergens &&
            data.ingredients[0].allergens.length > 0) ||
          (potentialAllergens && potentialAllergens.length > 0)
        ) {
          const uniqueAllergens = Array.from(
            new Set(
              data?.ingredients.flatMap((ingredient) => ingredient.allergens)
            )
          )
          // const allergensToShow = uniqueAllergens.filter(allergen => !potentialAllergens.includes(allergen))
          setAllergens(uniqueAllergens)
        } else setAllergens([])
      })
      .catch((error) => {
        console.log(error)
        toast.error('An error has occurred while loading the ingredients')
      })
  }, [product?.ID])

  const formatValues = (valueObj, label) => {
    if (
      !valueObj ||
      typeof valueObj.value === 'undefined' ||
      typeof valueObj.unit === 'undefined' ||
      (label === 'fibres' && parseInt(valueObj.value) === 0)
    ) {
      return null
    }

    return (
      <div className="mt-1">
        {label} ({valueObj.unit}): {valueObj.value}
      </div>
    )
  }

  const nutritionalValuesText = () => {
    if (!nutritionalValues) return
    const {
      salt,
      sugars,
      fibres,
      carbohydrates,
      saturated_fatty_acid,
      proteins,
      energy_value,
      fat,
      level,
    } = nutritionalValues

    const levelMapping = {
      '100_g': 'per 100 g',
      brute_portion: 'per porție brută',
      net_portion: 'per porție',
    }
    return [
      energy_value && (
        <div key="energy_value">
          ({levelMapping[level]}) Informații nutriționale
          <div className="mt-1">
            Valoare Energetică ({energy_value.unit}): {energy_value.value}
          </div>
        </div>
      ),
      formatValues(fat, 'Grăsimi'),
      formatValues(saturated_fatty_acid, 'Acizi grași saturați'),
      formatValues(carbohydrates, 'Glucide'),
      formatValues(sugars, 'Zaharuri'),
      formatValues(fibres, 'Fibre'),
      formatValues(proteins, 'Proteine'),
      formatValues(salt, 'Sare'),
      allergens && allergens.length > 0 ? (
        <div key="allergens" className="flex mt-4 mb-6 gap-5 flex-wrap">
          {allergens.map((allergen) => (
            <img
              src={allergenImages[allergen]}
              alt={allergen}
              key={allergen}
              className="w-[16px] h-[16px]"
            />
          ))}
          {allergens && allergens.length > 0 && (
            <BiInfoCircle
              style={{ color: '#9C9C9C', cursor: 'pointer' }}
              onClick={handleAllergensOpen}
            />
          )}
        </div>
      ) : (
        <div className="mt-5"></div>
      ),
    ].filter(Boolean)
  }

  const handleClose = () => {
    setCounter(1)
    setComment('')
    setModifiers([])
    if (product?.Modifiers?.length > 0) {
      product?.Modifiers?.forEach((modifierObj) => {
        if (Array.isArray(modifierObj.Items)) {
          modifierObj.Items.forEach((modifier) => {
            modifier.quantity = 0
          })
        } else if ('quantity' in modifierObj) {
          modifierObj.quantity = 0
        }
      })
    }
    setShow(false)
  }
  const handleCounterChange = (v) => {
    setCounter(v)
    let allModiArr = []
    if(Array.isArray(modifiers)){
      allModiArr.push(...modifiers)
      
    }else{
      Object.keys(modifiers).map((ele)=>{
        if(modifiers[ele]){
          allModiArr.push(...modifiers[ele])
        }
      })
    }
    // const existingItemInBasket = busket.findIndex((x)=>{



    //   if(x.item.ID === product.ID && allModiArr.length){
    //     let allModiSame = true;
    //     x.modifiers.map((modifier,index) => {
    //       if(modifier['ID'] == allModiArr[index]['ID'] && modifier['quantity'] == allModiArr[index]['quantity']){

    //       }else{
    //         allModiSame = false;
    //       }
    //     })
    //     return allModiSame
    //   }else{
    //     return false
    //   }
    // }
    // )
    let existingItemInBasket = -1
    if(basketId){
      existingItemInBasket = busket.findIndex((eleB)=>eleB.id == basketId)
    } 

    if (existingItemInBasket !== -1) {
      setModifiers((prevModifiers) => {
        let newModifier = [];
        
        newModifier = busket[existingItemInBasket].modifiers.map((modifier, i) => {
          const calculatedQuantity =
            (v > basketItem.qty)
              ? (modifier.quantity + modifier.quantity / basketItem.qty)
              : (v < basketItem.qty)
              ? (modifier.quantity - modifier.quantity / basketItem.qty)
              : (modifier.quantity);
      
          // Log the calculated quantity to the console
          console.log(`Modifier index: ${i}, Original quantity: ${modifier.quantity}, Calculated quantity: ${calculatedQuantity}`);
      
          return {
            ...modifier,
            quantity: calculatedQuantity,
          };
        });
      
        return newModifier;
      });
      setBusket((prv) =>
        prv.map((x, index) => {
          if (index === existingItemInBasket) {
            return {
              ...x,
              modifiers: x.modifiers.map((modifier, i) => {
                const calculatedQuantity =
                  (v > basketItem.qty)
                    ? (modifier.quantity + modifier.quantity / basketItem.qty)
                    : (v < basketItem.qty)
                    ? (modifier.quantity - modifier.quantity / basketItem.qty)
                    : (modifier.quantity);
          
                // Log the calculated quantity to the console
                console.log(`Modifier index: ${i}, Original quantity: ${modifier.quantity}, Calculated quantity: ${calculatedQuantity}`);
          
                return {
                  ...modifier,
                  quantity: calculatedQuantity,
                };
              }), // WORKED AS MULTIPLIER MODIFIER IF USER MULTIPLY THE PRODUCT
              qty: v,
            };
          }
          return x
        })
      )
    }
  }

  const handleModifierChange = (modifierId, selectedItems) => {
    let allSelectedItemsWithOutFilter = selectedItems
    let allproductModById = product.Modifiers.find((ele)=>ele.ID == modifierId);
    if(allproductModById){
      let allAppIds = allproductModById['Items'].map((eleMM)=>eleMM.ID)
      selectedItems = selectedItems.filter((eleS)=>allAppIds.includes(eleS.ID))
    }
    setModifiers((prevModifiers) => {
      let newModifier = []
      if (Array.isArray(selectedItems)) {
        // newModifier = fromFoodOrdering? selectedItems.map((val, i) => ({...val, quantity: (val.quantity * counter < selectedModifiers[i].quantity ?  selectedModifiers[i].quantity - val.quantity : val.quantity * counter > selectedModifiers[i].quantity ? selectedModifiers[i].quantity + val.quantity : selectedModifiers[i].quantity) })) : selectedItems
        newModifier = fromFoodOrdering
          ? selectedItems.map((val, i) => ({
              ...val,
              quantity: val.quantity * counter,
            }))
          : selectedItems
      } else {
        newModifier = [selectedItems]
      }

      const newModifiers = {
        ...prevModifiers,
        [modifierId]: newModifier,
      }

      return newModifiers
    })

    if(basketId){
      setBusket((prv) =>
        prv.map((x, index) => {
          if (x['id'] == basketId) {
            return {
              ...x,
              modifiers: allSelectedItemsWithOutFilter.map((val, i) => ({
                ...val,
                quantity: val.quantity * counter,
              })),
              qty: counter,
            };
          }
          return x
        })
      )
    }
  }

  useEffect(() => {
    let totalSum = (product.discountedProductPrice?product.discountedProductPrice:product.Price) * counter
    if (fromFoodOrdering && Object.values(modifiers).length == 0) {
      // CONDITION IF IT COMES FROM RECOMMENDATION IN FOOD ORDERING. SOMEHOW MODIFIERS IS EMPTY
      for (let j = 0; j < basketItem.modifiers.length; j++) {
        let totalModif =
          basketItem.modifiers[j].Price *
          (basketItem.modifiers[j].quantity > 0
            ? basketItem.modifiers[j].quantity
            : 1)
        totalSum = totalSum + totalModif
      }
    } else if (!fromFoodOrdering) {
      Object.values(modifiers).forEach((modifierSelectedItems) => {
        for (let i = 0; i < modifierSelectedItems.length; i++) {
          let priceTimesQty =
            modifierSelectedItems[i].Price *
            ((modifierSelectedItems[i].quantity > 0
              ? modifierSelectedItems[i].quantity
              : 1) *
              counter)
          totalSum = totalSum + priceTimesQty
        }
        // totalSum += modifierSelectedItems?.reduce(
        //   (acc, item) =>  Array.isArray(item) ? acc + item?.reduce((acc2, item2) => acc2 + (item2?.quantity ? (item2?.quantity || 0) * (item2?.Price || 0) : (item2?.Price || 0)), 0) : acc + (item?.quantity ? (item?.quantity || 0) * (item?.Price || 0) : (item?.Price || 0)),
        //   0
        // )
      })
    } else if (fromFoodOrdering) {
      Object.values(modifiers).forEach((modifierSelectedItems) => {
        if (Array.isArray(modifierSelectedItems)) {
          for (let i = 0; i < modifierSelectedItems.length; i++) {
            let priceTimesQty =
              modifierSelectedItems[i].Price *
              (modifierSelectedItems[i].quantity > 0
                ? modifierSelectedItems[i].quantity
                : 1)
            totalSum = totalSum + priceTimesQty
          }
        } else {
          let priceTimesQty =
            modifierSelectedItems.Price * modifierSelectedItems.quantity
          totalSum = totalSum + priceTimesQty
        }
      })
    }
    setTotalModifiersSum(totalSum)
  }, [modifiers, product, counter])

  const handleCommentChange = (e) => {
    setComment(e.target.value)
  }

  const handleAddToBasket = () => {
    const allSelectedModifiers = Object.values(modifiers).flat()

    const existingItemIndex = busket.findIndex(
      (x) =>
        x.item.ID === product.ID &&
        basketCompare(x.modifiers, allSelectedModifiers) &&
        x.comment === comment
    )
    if (existingItemIndex !== -1) {
      setBusket((prevBasket) =>
        prevBasket.map((item, index) =>
          index === existingItemIndex
            ? { ...item, qty: item.qty + counter }
            : item
        )
      )
    } else {
      const id = uuid()
      setBusket((prevBasket) => [
        ...prevBasket,
        {
          item: product,
          qty: counter,
          comment,
          modifiers: allSelectedModifiers,
          id,
        },
      ])
    }

    toast.success('Successfully Added new product to your cart.', {
      position: 'bottom-right',
    })
    handleClose()
  }

  const handleModifyProduct = () => {
    if (!customerAvailabilityStatus) {
      toast.error(t('product.error_order_acceptance'), {
        position: 'bottom-right',
      })
      return false
    }

    let allSelectedModifiers
    if (Array.isArray(modifiers)) {
      allSelectedModifiers = modifiers
    } else {
      allSelectedModifiers = Object.entries(modifiers).reduce(
        (res, [modifierId, items]) => {
          let flatItems = items.flat()
          flatItems = flatItems.filter((ele)=>{
            if(ele){
              return true
            }
          })
          res.push(
            ...flatItems.map((modifier) => ({
              ...modifier,
              parentId: modifierId,
              // quantity: (modifier?.quantity ?? 1) * (fromFoodOrdering ? 1 : counter)
              quantity:
                (modifier?.quantity == 0 || !modifier.quantity
                  ? 1
                  : modifier?.quantity) * (fromFoodOrdering ? 1 : counter),
            }))
          )
          return res
        },
        []
      )
    }
    setBusket((prevBusket) => {
      const existingItemIndex = busket.findIndex(
        (basketItem) => basketItem.id === basketId
      )
      const modifiersMatchIndex = busket.findIndex((busketItem, index) => {
        if (index === existingItemIndex) return false
        return (
          basketCompare(
            busketItem.modifiers,
            allSelectedModifiers,
            busketItem.qty
          ) &&
          busketItem.comment === comment &&
          busketItem.item.ID === product.ID
        )
      })

      if (modifiersMatchIndex > -1) {
        return prevBusket
          .filter(
            (basketItem) =>
              basketItem.id !== prevBusket?.[existingItemIndex]?.id
          )
          .map((basketItem, index) => {
            if (index !== modifiersMatchIndex) return basketItem
            return {
              ...basketItem,
              qty: basketItem.qty + counter,
              locationId: location?.LocationId,
              modifiers: basketItem.modifiers.map((modif) => ({
                ...modif,
                quantity: modif.quantity + counter,
              })),
            }
          })
      }

      if (existingItemIndex > -1) {
        return prevBusket.map((basketItem, index) => {
          if (index !== existingItemIndex) return basketItem

          return {
            ...basketItem,
            locationId: location.LocationId,
            modifiers: allSelectedModifiers,
            comment,
            qty: counter,
          }
        })
      }
      return [
        ...prevBusket,
        {
          item: product,
          qty: counter,
          comment,
          locationId: location.LocationId,
          modifiers: allSelectedModifiers,
          id: uuid(),
        },
      ]
    })
    handleClose()
    toast.success(`Successfully modified your product in the cart.`, {
      position: 'bottom-right',
    })
  }

  useEffect(() => {
    if (show) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
    // When the modal is hidden or the component is unmounted, remove the class
    return () => {
      document.body.classList.remove('no-scroll')
    }
  }, [show])

  useEffect(() => {
    setshowDropDown(false)
    const descHeight = window.innerHeight * 0.67 + 'px'
    setOutOfStockHeight(descHeight)
    if (containerRef.current && h3Ref.current && h4Ref.current && show) {
      const containerWidth = containerRef.current.clientWidth
      const h3Width = h3Ref.current.clientWidth
      const h4Width = h4Ref.current.clientWidth

      if (h3Width + h4Width > containerWidth) {
        setWidthClass('w-full')
      } else {
        setWidthClass('w-[fit-content]')
      }
    }
  }, [viewportWidth, show])

  useEffect(() => {
    if (show && product) {
      if (basketItem !== undefined) {
        setComment(basketItem.comment)
        // setModifiers(existItem.modifiers)
        setCounter(basketItem.qty)
      }
    } else {
      setCounter(1)
      setComment('')
      setModifiers([])
    }
  }, [basketItem, product, show])

  const addToBasketText = (text) => {
    return (
      <span className="font-medium">
        {t(`${text}`)} &nbsp; &nbsp; {formatPrice(totalModifiersSum)} {unit}
      </span>
    )
  }
  return show ? (
    <div className="fixed w-full h-full bg-[#878787c2] left-0 top-0 z-10 backdrop-blur-sm flex justify-center items-center">
      <div
        className={`relative sm:w-[600px] w-[95%] bg-white rounded-lg shadow-lg ${
          product.Available !== undefined && !product.Available
            ? !showDropDown
              ? 'h-auto max-h-[95%]'
              : 'sm:h-[90%] h-[95%]'
            : 'sm:h-[90%] h-[95%]'
        }`}
      >
        {!basketId && (
          <div
            className="relative h-[180px] rounded-tl-lg rounded-tr-lg flex items-center"
            style={{ backgroundColor: '#EEF3F6' }}
          >
            <ProductImage
              product={product}
              style={{ objectFit: 'cover' }}
              alt="Product"
              isLazy={false}
              className="w-full h-full object-cover rounded-tl-lg rounded-tr-lg productDialog__image"
            />
          </div>
        )}
        <button
          className="absolute top-[15px] right-[10px] bg-[#FFF] transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg rounded-[50%] p-[10px]"
          onClick={handleClose}
        >
          <RxCross2 style={{ color: '#000000' }} />
        </button>
        <div
          style={
            product.Available !== undefined && !product.Available
              ? { height: 'auto', maxHeight: outOfStockHeight }
              : { height: `${basketId ? '90%' : 'calc(100% - 260px)'}` }
          }
          className={`px-[20px] pt-[20px] overflow-y-scroll overscroll-none`}
        >
          <h1 className="text-xl font-semibold mb-4">{productName}</h1>

          {/* <h1 className="text-xl font-semibold mb-4">{product?.Name}</h1> */}
          <ProductDescription
            description={longDescription ? longDescription : shortDescription}
          />
          {/* )} */}
          <div className="flex-1 flex flex-col h-auto pt-[10px]">
            {!basketId && nutritionalValues?.energy_value && (
              <DropdownProduct
                title={t('product.nutrition_value')}
                content={nutritionalValuesText()}
                setShowProduct={() => setshowDropDown((prev) => !prev)}
                showProduct={showDropDown}
              />
            )}
            {autoOrder &&
              (product?.Available || product?.Available === undefined) &&
              product?.Modifiers?.length > 0 && (
                <div>
                  {product?.Modifiers?.map((currentModifier, idx) => {
                    return (
                      <Modifier
                        key={idx}
                        modifier={currentModifier}
                        initialValue={
                          selectedModifiers ||
                          modifiers[currentModifier.ID] ||
                          []
                        }
                        onChange={handleModifierChange}
                        fromFoodOrdering={fromFoodOrdering}
                        mainCounter={counter}
                      />
                    )
                  })}
                </div>
              )}
            {autoOrder &&
              (product?.Available || product?.Available === undefined) && (
                <TextArea
                  placeholder={t('product.mention_placeholder')}
                  // placeholder="Mențiuni ..."
                  className="w-full mt-[20px] min-h-[100px]"
                  onChange={handleCommentChange}
                  value={comment}
                />
              )}
            {autoOrder &&
              (product?.Available || product?.Available === undefined) && (
                <Counter
                  colorCode={colorCode}
                  className="pt-[50px] pb-[20px]"
                  value={counter}
                  onChange={handleCounterChange}
                />
              )}
          </div>
        </div>
        {autoOrder &&
          (product?.Available || product?.Available === undefined) && (
            <div className="flex flex-col items-center justify-center h-[80px] absolute bottom-0 w-full">
              {basketId ? (
                <Button
                  variant="outline"
                  colorCode={colorCode}
                  textColor={buttonTextColorCode}
                  text={addToBasketText('product.edit')}
                  onClick={handleModifyProduct}
                />
              ) : (
                <Button
                  variant="outline"
                  colorCode={colorCode}
                  textColor={buttonTextColorCode}
                  text={addToBasketText('product.add')}
                  onClick={handleModifyProduct}
                />
              )}
            </div>
          )}
      </div>
      <AllergensPopup
        setIsVisible={setIsVisible}
        isVisible={isVisible}
        allergens={allergens}
        currentProduct={product}
      />
    </div>
  ) : null
}

export default ProductDialog
