import { useContext } from 'react'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { GlobalContext } from '../../appContext/GlobalContext'
import BusketBox from '../FoodOrder/BusketBox'
import { SummaryCost } from '../FoodOrder/OrderSummary'
import { getProcessingCost, getTotalCost, getTotalCostLocationBased } from '../../utils/price'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Feedback from '../../components/Feedback/Feedback'
import { formatPrice } from '../../utils/number'
import { useLocation } from 'react-router-dom'

const ThankyouIcon = () => {
  return (
      <svg width="120" height="120" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="60" height="60" rx="30" fill="#D7FFCD"/>
        <path d="M43.3332 20.8333L24.9998 39.1667L16.6665 30.8333" stroke="#45B64A" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
  )
}

const ThankYou = () => {
  const { t } = useTranslation()
  const { busket, setBusket, tips, setTips, delivPickupType, delivPickupPrice } = useContext(ProductBusketContext)
  const { method, colorCode ,location} = useContext(GlobalContext)
  // const productTotal = getTotalCost(busket)
  const productTotal = getTotalCostLocationBased(busket,location?.LocationId)
  const processingCost = method === 'online' ? getProcessingCost(busket) : 0
  const thankYouCode = window.localStorage.getItem("thankyou_code")

  const isInitialMount = useRef(true)

  const { state } = useLocation();
  const { discountAmount, promoCodeDiscount } = state || {};


  useEffect(() => {
    return () => {
      if (isInitialMount.current) {
        isInitialMount.current = false
      } else {
        window.localStorage.removeItem('busket')
        window.localStorage.removeItem('tips')
        //sessionStorage.setItem('method', 'cash')
        setBusket([])
        setTips(0)
      }
    }
  }, [setBusket, setTips])

  return (
    <div className="flex flex-col justify-center items-center p-[10px]">
      {thankYouCode && (
        <>
          <div>{t('product.thankyou_code')}</div>
          <div className='font-semibold'>{thankYouCode}</div>
        </>
      )}
      <div className="p-[50px]">
        <ThankyouIcon/>
      </div>
      <h2 className="text-[20px] font-semibold mb-[20px] text-center">
        {thankYouCode ? t('product.thank_notes_pickup') : t('product.thankyou_notes')}
      </h2>
      <div className="flex justify-start w-full">
        {/* <h3 className="text-[18px] font-medium">Detaliile comenzii</h3> */}
        <h3 className="text-[18px] font-medium">{t('product.order_details')}</h3>
      </div>
      {busket.map((x) => (
        <BusketBox
          key={x.id}
          item={x.item}
          qty={x.qty}
          dynamic={false}
          modifiers={x.modifiers}
        />
      ))}
      <div className="relative p-[10px] mt-[20px] rounded-br-[20px] rounded-bl-[20px] w-full">
      {
          discountAmount?(
            <>
              <SummaryCost
              title={t('checkout.subtotal')}
              price={formatPrice((productTotal['discountedTotalPrice']?productTotal['discountedTotalPrice']:productTotal['result']))}
              
            />
            <SummaryCost title={`${t('checkout.dis_applied')} (${promoCodeDiscount.discount}%)`} price={`-${discountAmount}`} textColor={colorCode}/>
            {delivPickupType && (
                <SummaryCost
                  title={t(`product.${delivPickupType}`)}
                  price={delivPickupPrice}
                  border={false}
                />
              )}
            
            <SummaryCost
              title={t('product.tips')}
              price={tips ? tips : 0.0}
              border={true}
            />

            <SummaryCost
              title={t('product.total')}
              price={
                {'result':productTotal['result'] + processingCost - discountAmount + tips  + (delivPickupType ? delivPickupPrice : 0),
                  'discountedTotalPrice':productTotal['discountedTotalPrice']+ processingCost + tips - discountAmount  + (delivPickupType ? delivPickupPrice : 0)}
              }

              bold
            />
            </>
          
          ):(

            <>
            
            <SummaryCost
              title={t('product.prod_cost')}
              price={{'result':productTotal['result'],'discountedTotalPrice':productTotal['discountedTotalPrice']}}
              border={false}
            />
            {delivPickupType && (
              <SummaryCost
                title={t(`product.${delivPickupType}`)}
                price={delivPickupPrice}
                border={false}
              />
            )}
            <SummaryCost title={t('product.tips')} price={tips} border={false} />
            {/* <SummaryCost
              title={t('product.proc_cost')}
              price={processingCost}                       NO PROCESSING COST FOR THE MOMENT
              border={false}
            /> */}
    
              
    
            {/* <div
              className="w-[95%] h-[2px] mx-auto"
              style={{ backgroundColor: colorCode }}
            ></div>
    
                {
                  productTotal['discountedTotalPrice']<productTotal['result'] &&(
                    <SummaryCost
                      title={t('product.totalDiscounts')}
                      price={formatPrice(productTotal['result'] - productTotal['discountedTotalPrice'])}                                        
                      // icon={<AiOutlineExclamationCircle />}
                      border={true}
                    />
                  )
                } */}
            <SummaryCost
              title={t('product.total')}
              price={
                {'result':productTotal['result'] + processingCost + tips + (delivPickupType ? delivPickupPrice : 0),
                  'discountedTotalPrice':productTotal['discountedTotalPrice']+ processingCost + tips + (delivPickupType ? delivPickupPrice : 0)}
              }
              bold
              border={false}
            />
            </>
          )
      }
      

      </div>
      <Feedback setBusket={setBusket} setTips={setTips} colorCode={colorCode} />
    </div>
  )
}

export default ThankYou
