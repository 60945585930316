import React, { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { GlobalContext } from '../../appContext/GlobalContext'
import {
  getTotalCost,
  getProcessingCost,
  getTotalCostLocationBased,
} from '../../utils/price'
import { formatPrice } from '../../utils/number'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Button } from '../../common'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import useViewportWidth from '../../hooks/useViewportWidth'
import Checkout from '../FinishOrder/Checkout'
import { RxCross2 } from 'react-icons/rx'
import DelivPickupDetails from './DelivPickupDetails'
import convertTimeStringToDateISO from '../../utils/convertTimeStringToDateISO'

const CashOrderNotAvailable = ({ isVisible, setIsVisible, viewportWidth }) => {
  const { t } = useTranslation()
  return (
    <div
      className={`fixed z-50 top-0 left-0 w-full h-full bg-[#00000091] overflow-auto transition-all ${isVisible
        ? 'opacity-100 pointer-events-auto'
        : 'opacity-0 pointer-events-none'
        }`}
      onClick={() => setIsVisible(false)}
    >
      <div
        className="absolute sm:w-[630px] w-[90%] top-[100px] bg-white shadow-lg border p-[30px] md:p-[65px] md:pr-[95px] flex flex-col rounded-[12px] ml-[5%] mr-[5%]"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-[10px] sm:hidden right-[10px] bg-[#FFF] transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg rounded-[50%] p-[10px]"
          onClick={() => setIsVisible(false)}
        >
          <RxCross2 style={{ color: '#000000' }} />
        </button>

        <div className="flex justify-center">
          <img alt="" src="/assets/images/icons/exclamation_icon.svg" />
        </div>

        <div
          className={`${viewportWidth < 367 ? 'text-[14px]' : 'text-[16px]'
            } flex flex-col justify-center items-center p-[5px]`}
        >
          <p className="text-center">{t('OrderSummary.para1')}</p>
          <p className="ml-[3px] text-center">{t('OrderSummary.para2')}</p>
        </div>

        <button className="mt-[10%]" onClick={() => setIsVisible(false)}>
          Ok
        </button>
      </div>
    </div>
  )
}

const OnlineOrderNotAvailable = ({
  isVisible,
  setIsVisible,
  viewportWidth,
}) => {
  return (
    <div
      className={`fixed z-50 top-0 left-0 w-full h-full bg-[#00000091] overflow-auto transition-all ${isVisible
        ? 'opacity-100 pointer-events-auto'
        : 'opacity-0 pointer-events-none'
        }`}
      onClick={() => setIsVisible(false)}
    >
      <div
        className="absolute sm:w-[630px] w-[90%] top-[100px] bg-white shadow-lg border p-[30px] md:p-[65px] md:pr-[95px] flex flex-col rounded-[12px] ml-[5%] mr-[5%]"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-[10px] sm:hidden right-[10px] bg-[#FFF] transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg rounded-[50%] p-[10px]"
          onClick={() => setIsVisible(false)}
        >
          <RxCross2 style={{ color: '#000000' }} />
        </button>

        <div className="flex justify-center">
          <img alt="" src="/assets/images/icons/sad-face-icon.svg" />
        </div>

        <div
          className={`${viewportWidth < 366 ? 'text-[13px]' : 'text-[14px]'
            } flex justify-center items-center p-[5px]`}
        >
          <p>Plata online nu funcționează pentru moment.</p>
        </div>

        <button className="mt-[10%]" onClick={() => setIsVisible(false)}>
          Ok
        </button>
      </div>
    </div>
  )
}

export const SummaryCost = ({
  title,
  price,
  icon,
  unit = 'RON',
  bold = false,
  border = false,
  modifiers,
  qty,
  boldTitle = false,
  textColor = false
}) => {
  const [showTip, setShowTip] = useState(false)
  const tipTimeoutRef = useRef(null)
  const { colorCode, selectedLanguage } = useContext(GlobalContext)
  const handleShowTip = () => {
    setShowTip(!showTip)
  }

  useEffect(() => {
    console.log("Summary data...price,",price);
    

    if (tipTimeoutRef.current) {
      clearTimeout(tipTimeoutRef.current)
    }

    if (showTip) {
      tipTimeoutRef.current = setTimeout(() => {
        setShowTip(false)
      }, 2000)
    }
  }, [showTip])

  const modifierPrice =
    modifiers &&
    modifiers.reduce(
      (result, modifier) =>
        result +
        (modifier?.quantity
          ? (modifier?.Price || 0) * (modifier?.quantity || 0)
          : modifier?.Price || 0),
      0
    )

  return (
    <div
      className={`${border ? `[border-bottom:2px_solid]` : ''}`}
      style={border ? { borderColor: colorCode } : {}}
    >
      <div
        className={`flex justify-between items-center mt-[5px] text-[18px] py-[10px] ${bold ? 'font-semibold sm:text-[24px]' : 'font-medium sm:text-[20px]'
          }`}
      >
        {/* <h5 className="flex items-center font-[Poppins] text-[18px] leading-[27px] font-medium " > */}
        <h5
          className={`flex items-center font-[Poppins] leading-[27px]  ${title === 'Total' || title === 'Total Price'
              ? 'text-[20px]] font-semibold'
              : 'text-[18px] font-medium'
            }`}
            style={{
              color:`${textColor?textColor:''}`
            }}
        >
          {icon && (
            <div className="absolute left-[3px]">
              <span className="relative cursor-pointer" onClick={handleShowTip}>
                {icon}

                {showTip && (
                  <div className="absolute left-0 bottom-4 ml-0.5 z-10">
                    <div
                      style={{
                        boxShadow:
                          '2px 4px 14.2px 0px rgba(183, 204, 235, 0.55)',
                      }}
                      className="-ml-[10px] bg-white gap-2 text-black text-[13px] px-[14px] py-[23px] max-h-[250px] overflow-auto w-[290px] rounded-[12px] shadow-lg flex flex-col"
                    >
                      <div className="flex justify-between">
                        <h5 className="flex items-center text-[16px]">
                          Plată mai târziu
                        </h5>
                        <h4 className="text-[16px] font-bold">
                          {formatPrice(101.99)}
                          <small> {unit}</small>
                        </h4>
                      </div>
                      <div
                        className="w-full h-[2px]"
                        style={{ backgroundColor: colorCode }}
                      ></div>
                      <div className="flex justify-between">
                        <h5 className="flex items-center text-[16px]">
                          Pizza Canibale
                        </h5>
                        <h4 className="text-[16px] font-bold">
                          {formatPrice(62.0)}
                          <small> {unit}</small>
                        </h4>
                      </div>
                      <div className="flex justify-between">
                        <h5 className="flex items-center text-[16px]">
                          Ciorba a la grec
                        </h5>
                        <h4 className="text-[16px] font-bold">
                          {formatPrice(62.0)}
                          <small> {unit}</small>
                        </h4>
                      </div>
                    </div>
                    <div
                      style={{
                        width: 0,
                        height: 0,
                        borderLeft: '8px solid transparent',
                        borderRight: '8px solid transparent',
                        borderTop: '8px solid',
                        borderTopColor: 'white',
                      }}
                    ></div>
                  </div>
                )}
              </span>
            </div>
          )}
          {qty && qty > 1 && `${qty} x `}
          {title}
        </h5>
        {/* <h4
          className={`text-[18px] font-medium text-right ${
            bold ? 'sm:text-[24px]' : 'sm:text-[20px]'
          }`}
          style={{ whiteSpace: 'nowrap' }}
        >
          { qty
            ? formatPrice(price * qty)
            : formatPrice(price)
            }{' '}
          {unit}{' '}
        </h4> */}

        <h4
          className={`text-[18px] text-right ${bold ? 'sm:text-[24px] font-semibold' : 'sm:text-[20px] font-medium'
            }`}
          style={{ whiteSpace: 'nowrap',
             color:`${textColor?textColor:''}`
           }}
        >
          {price['result'] ? (
            (price['discountedTotalPrice']>0)?(
              qty ? (
                <>
                
                  <span>
                    {formatPrice(price['discountedTotalPrice'] * qty)} {unit}
                  </span> 
                </>
              ) : (
                <>
                  {/* <span className={` ${bold ? 'font-semibold' : 'font-medium'}`}>
                    {formatPrice(price)}
                  </span>{' '}
                  <span className={` ${bold ? 'font-semibold' : 'font-medium'}`}>
                    {unit}
                  </span> */}

                 
                  <span>
                    {formatPrice(price['discountedTotalPrice'])} {unit}
                  </span> 
                </>
              )
            ):(
              qty ? (
                <>
                  <span>{formatPrice(price['result'] * qty)}</span> {unit}
                </>
              ) : (
                <>
                  {/* <span className="font-medium">{formatPrice(price)}</span> */}
                  <span className={` ${bold ? 'font-semibold' : 'font-medium'}`}>
                    {formatPrice(price['result'])}
                  </span>{' '}
                  {/* {unit} */}
                  <span className={` ${bold ? 'font-semibold' : 'font-medium'}`}>
                    {unit}
                  </span>
                </>
              )

            )
          ):(
            qty ? (
              <>
                <span>{formatPrice(price * qty)}</span> {unit}
              </>
            ) : (
              <>
                {/* <span className="font-medium">{formatPrice(price)}</span> */}
                <span className={` ${bold ? 'font-semibold' : 'font-medium'}`}>
                  {formatPrice(price)}
                </span>{' '}
                {/* {unit} */}
                <span className={` ${bold ? 'font-semibold' : 'font-medium'}`}>
                  {unit}
                </span>
              </>
            )

          )}
          
        </h4>


      </div>
      {modifiers &&
        modifiers.map((item, index) => {
          const getProductName = (item, language) => {
            const key = `Name${language}`
            return item[key] || item.Name
          }

          const productName = getProductName(item, selectedLanguage)
          return (
            <div
              className="text-[14px] font-normal flex justify-between items-center"
              key={index}
            >
              <span>
                {item?.quantity && item?.quantity > 1 && `${item?.quantity} x`}{' '}
                {selectedLanguage && productName}
              </span>
              <span style={{ whiteSpace: 'nowrap', fontWeight: '500' }}>
                {qty
                  ? formatPrice(
                    item?.quantity
                      ? item?.Price * item?.quantity
                      : item?.Price
                  )
                  : formatPrice(
                    item?.quantity
                      ? item?.Price * item?.quantity
                      : item?.Price
                  )}{' '}
                {unit}
              </span>
            </div>
          )
        })}
    </div>
  )
}

const OrderSummary = ({ final, method, selectedTip, delivOrPickup,promoCodeDiscount }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    busket,
    getCompleteInvoice,
    tips,
    setTips,
    delivPickupType,
    deliveryData,
    pickupData,
  } = useContext(ProductBusketContext)
  const {
    productId,
    uuid,
    onlinePayment,
    cashPayment,
    colorCode,
    selectedLanguage,
    location,
  } = useContext(GlobalContext)
  // const productTotal = getTotalCost(busket)
  const productTotal = getTotalCostLocationBased(busket, location?.LocationId)
  const processingCost = method === 'online' ? getProcessingCost(busket) : 0
  const [totalSum, setTotalSum] = useState(0)
  const [subTotalSum, setSubTotalSum] = useState(0)
  const [totalSumWithDiscount, setTotalSumWithDiscount] = useState(0)
  const viewportWidth = useViewportWidth()
  const [isLoading, setIsLoading] = useState(false)
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [cashOrderDialog, setCashOrderDialog] = useState(false)
  const [onlineOrderDialog, setOnlineOrderDialog] = useState(false)
  const [address, setAddress] = useState(null)
  const [discountAmount, setDiscountAmount] = useState(0)

  useEffect(() => {
    if (method === 'cash' && cashPayment === false) setCashOrderDialog(true)
    if (method === 'online' && onlinePayment === false)
      setOnlineOrderDialog(true)
  }, [method, onlinePayment, cashPayment])

  useEffect(() => {
   
    setTips(selectedTip ? (productTotal['discountedTotalPrice']?productTotal['discountedTotalPrice']:productTotal['result']) * (selectedTip / 100) : 0)
    setTotalSum((productTotal['result']) + processingCost + tips)
    setSubTotalSum((productTotal['discountedTotalPrice']?productTotal['discountedTotalPrice']:productTotal['result']))
    setTotalSumWithDiscount((productTotal['discountedTotalPrice']?productTotal['discountedTotalPrice']:productTotal['result']) + processingCost + tips)
    window.localStorage.setItem(
      'tips',
      JSON.stringify(selectedTip ? (productTotal['discountedTotalPrice']?productTotal['discountedTotalPrice']:productTotal['result']) * (selectedTip / 100) : 0)
    )

    let discountAmt = 0
    if(promoCodeDiscount){
      let totalAmt = (productTotal['discountedTotalPrice']?productTotal['discountedTotalPrice']:productTotal['result'])
      discountAmt = parseFloat((totalAmt*(parseFloat(promoCodeDiscount.discount)/100)).toFixed(2))
      setDiscountAmount(discountAmt)
    }else{
      setDiscountAmount(0)

    }
  }, [setTips, productTotal, selectedTip, tips, processingCost,promoCodeDiscount])

  const handleOrderNextClick = () => {
    if (delivPickupType == 'delivery' && !address) {
      toast.error(t('product.select_address_first'))
    } else if (delivPickupType == 'pickup' && !pickupData?.phone) {
      toast.error(t('product.fill_phone'))
    } else if (final) {
      if (method === 'cash') {
        if (
          delivPickupType == 'pickup' &&
          !pickupData?.email &&
          !pickupData?.phone
        ) {
          toast.error(t('product.fill_email_phone'))
        } else if (cashPayment === true) {
          const dataBody =
            delivPickupType == 'pickup'
              ? {
                ...getCompleteInvoice(),
                tips: formatPrice(tips),
                type: 'pickup',
                deliveryDate: convertTimeStringToDateISO(
                  `${pickupData.day}, ${pickupData.time}`
                ),
                email: pickupData.email,
                delivery_phone: pickupData.phone,
                delivery_email: pickupData.email,
                delivery_firstName: pickupData.firstName.split(' ')[0] ?? '',
                delivery_lastName: pickupData.firstName.split(' ')[1] ?? '',
              }
              : delivPickupType == 'delivery'
                ? {
                  ...getCompleteInvoice(),
                  tips: formatPrice(tips),

                  type: 'delivery',
                  deliveryAddress: {
                    postalCode: deliveryData.postalCode.toString(),
                    street: deliveryData.street,
                    city: deliveryData.city,
                    county: deliveryData.county,
                    long: deliveryData.long,
                    lat: deliveryData.lat,
                    country: deliveryData.country,
                    isDeliveryAddress: true,
                    isInvoiceAddress: true,
                    details: deliveryData.details,
                  },
                  noteForCourier: deliveryData.noteForCourier,
                  delivery_firstName: deliveryData.firstName,
                  delivery_lastName: deliveryData.lastName,
                  delivery_phone: deliveryData.phone.toString(),
                  delivery_email: deliveryData.email,
                }
                : {
                  ...getCompleteInvoice(),
                  tips: formatPrice(tips),
                }
          setIsLoading(true)
          fetch(`${process.env.REACT_APP_API_URL}/order/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataBody),
          })
            .then((res) => res.json())
            .then((data) => {
              if (delivPickupType == 'pickup' && data?.orderNo) {
                window.localStorage.setItem('thankyou_code', data.orderNo)
              }
              navigate('/food-ordering/thankYou', {
                state: {
                  location: `${location?.LocationId}`,
                },
              })
              //need changes
            })
            .catch((err) => {
              navigate('/wrong')
            })
            .finally(() => {
              setIsLoading(false)
            })
        } else setCashOrderDialog(true)
      } else if (method === 'online') {
        if (viewportWidth < 960) {
          onlinePayment === false
            ? setOnlineOrderDialog(true)
            : setIsDialogVisible(true)
        } else {
          navigate('/wrong')
        }
      } else {
        toast.error('Please check your phone number and email address', {
          position: 'bottom-right',
        })
      }
    } else {
      navigate('/food-ordering/finish', {
        state: {
          location: `${location?.LocationId}`,
        },
      })
    }
  }

  const handleAddMoreProducts = () => {
    navigate(`/product/${productId}?${uuid}`)
  }

  const handleShowTerms = () => {
    navigate('/terms-and-conditions')
  }

  const handleShowPrivacy = () => {
    navigate('/privacy-policy')
  }

  return (
    <div className="relative mx-[5px] px-[6px] py-[10px] md:p-[10px] mt-[20px]">
      <h3 className="text-[20px] sm:text-[20px] font-[Poppins] font-semibold mt-[10px] text-center">
        {t('product.summary')}
      </h3>
      {final ? (
        <>
          {
            discountAmount?(
              <>
                <SummaryCost
                title={t('checkout.subtotal')}
                price={formatPrice(subTotalSum)}
              
              />
              <SummaryCost title={`${t('checkout.dis_applied')} (${promoCodeDiscount.discount}%)`} price={`-${discountAmount}`} textColor={colorCode}/>
              <SummaryCost
                title={t('product.tips')}
                price={tips ? tips : 0.0}
                border={true}
              />

              <SummaryCost
                title={t('product.total')}
                price={{'result':formatPrice(totalSum-discountAmount),'discountedTotalPrice':formatPrice(totalSumWithDiscount-discountAmount)}}
                bold
              />
              </>
             
            ):(
              <>
              
              <SummaryCost title={t('product.prod_cost')} price={productTotal} />
              <SummaryCost
                title={t('product.tips')}
                price={tips ? tips : 0.0}
                border={true}
              />
              <SummaryCost
                title={t('product.total')}
                price={{'result':formatPrice(totalSum),'discountedTotalPrice':formatPrice(totalSumWithDiscount)}}
                bold
              />
              </>

            )

          }
          
        </>
      ) : (
        <>
          {busket &&
            busket.map((item, index) => {
              if (item.locationId === location.LocationId) {
                const getProductName = (item, language) => {
                  const key = `Name${language}`
                  return item.item[key] || item.item.Name
                }

                const productName = getProductName(item, selectedLanguage)
                return (
                  <SummaryCost
                    title={selectedLanguage && productName}
                    // title={item?.item?.Name}
                    price={item.item.discountedProductPrice?{'result':item.item.Price,'discountedTotalPrice':item.item.discountedProductPrice}:item.item.Price}
                    index={index}
                    border={busket.length - 1 === index}
                    key={index}
                    modifiers={item.modifiers}
                    qty={item?.qty}
                  />
                )
              }
            })}
        </>
      )}
      {/* <SummaryCost
                title={t('product.proc_cost')}
                price={processingCost}                                         NO PROCESSING COST FOR THE MOMENT
                // icon={<AiOutlineExclamationCircle />}
                border={true}
            /> */}
            {/* {
              totalSumWithDiscount<totalSum &&(
                <SummaryCost
                  title={t('product.totalDiscounts')}
                  price={formatPrice(totalSum - totalSumWithDiscount)}                                        
                  // icon={<AiOutlineExclamationCircle />}
                  border={true}
                />
              )
            } */}
      
      {
        !final?(
          <SummaryCost
            title={t('product.total')}
            price={{'result':formatPrice(totalSum),'discountedTotalPrice':formatPrice(totalSumWithDiscount)}}
            bold
          />
        ):''
      }

      
      {(location?.delivery || location?.pickup) &&
        (delivOrPickup == 'delivery' || delivOrPickup == 'pickup') &&
        !uuid && (
          <DelivPickupDetails
            title={delivOrPickup}
            className="px-[15px] my-5"
            address={address}
            changeAddress={setAddress}
            isPaymentCash={method == 'cash'}
          />
        )}
      {final && (
        <p className="mt-[5px] text-[14px]">
          {t('product.consent1')}
          &nbsp;
          <button onClick={handleShowTerms}>
            <u> {t('product.consent2')}</u>
          </button>
          &nbsp;{t('product.consent3')}&nbsp;
          <button onClick={handleShowPrivacy}>
            <u> {t('product.consent4')} </u>
          </button>
        </p>
      )}
      <div className="flex justify-center mt-[30px] font-medium">
        {final ? (
          <Button
            variant="outline"
            className="w-full font-medium"
            colorCode={colorCode}
            text={
              final
                ? isLoading
                  ? 'Trimitere ...'
                  : t('product.send')
                : t('product.next')
            }
            disabled={isLoading}
            onClick={handleOrderNextClick}
          />
        ) : (
          <Button
            colorCode={colorCode}
            variant="outline"
            className="w-full font-medium"
            text={t('product.more')}
            onClick={handleAddMoreProducts}
          />
        )}
      </div>
      <Checkout isVisible={isDialogVisible} setIsVisible={setIsDialogVisible} discountAmount={discountAmount} promoCodeDiscount={promoCodeDiscount}/>
      <CashOrderNotAvailable
        isVisible={cashOrderDialog}
        setIsVisible={setCashOrderDialog}
        viewportWidth={viewportWidth}
      />
      <OnlineOrderNotAvailable
        isVisible={onlineOrderDialog}
        setIsVisible={setOnlineOrderDialog}
        viewportWidth={viewportWidth}
      />
    </div>
  )
}

export default OrderSummary
