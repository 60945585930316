import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCreditCard, AiOutlineWallet } from 'react-icons/ai'
import CircleCheckBox from '../../common/CircleCheckBox'
import { LiaWalletSolid } from 'react-icons/lia'

const PaymentSelector = ({ method, setMethod, colorCode }) => {
  const { t } = useTranslation()

  const handleChangePaymentCash = (checked) => {
    if (!checked) return
    setMethod('cash')
  }
  const handleChangePaymentOnline = (checked) => {
    if (!checked) return
    setMethod('online')
  }

  return (
    <div>
      <div className="flex flex-col mt-[10px] px-[5px] ">
        <div className="text-[18px] font-medium">Payment method</div>
        <div
          className={`cursor-pointer relative pt-[20px] pl-2 pb-1 w-full flex items-center justify-between`}
          onClick={() => handleChangePaymentCash(true)}
        >
          <div className="flex gap-[10px]">
            <CircleCheckBox
              colorCode={colorCode}
              checked={method === 'cash'}
              onChange={(checked) => handleChangePaymentCash(checked)}
            />
            <h4 className="font-normal text-[16px]">{t('product.cash')}</h4>
          </div>
          <span className="text-[2rem]">
            <div className="pr-[4px]">
              <img alt="Cash Icon" src="/assets/images/icons/wallet_icon.svg" />
            </div>
          </span>
        </div>
        <div
          className={`cursor-pointer relative py-[20px] pl-2 w-full flex items-center justify-between`}
          onClick={() => handleChangePaymentOnline(true)}
        >
          <div className="flex gap-[10px]">
            <CircleCheckBox
              colorCode={colorCode}
              checked={method === 'online'}
              onChange={(checked) => handleChangePaymentOnline(checked)}
            />
            <h4 className="font-normal text-[16px]">{t('product.online')}</h4>
          </div>
          <span className="text-[2rem]">
            <div className="pr-[4px]">
              <img alt="Cash Icon" src="/assets/images/icons/cash_icon.svg" />
            </div>
          </span>
        </div>
        {/*<div*/}
        {/*    className={`cursor-pointer relative p-[20px] w-full flex items-center justify-between`}*/}
        {/*    onClick={() => handleChangePayment('pay_later')}*/}
        {/*>*/}
        {/*  <div className="flex gap-[10px]">*/}
        {/*    <CircleCheckBox*/}
        {/*        checked={method === 'pay_later'}*/}
        {/*        onChange={(checked) => handleChangePayment('pay_later')}*/}
        {/*    />*/}
        {/*    <h4 className="font-bold text-[1.1rem]">{t('product.pay_later')}</h4>*/}
        {/*  </div>*/}
        {/*  <span className="text-[2rem]">*/}
        {/*  <img alt="Cash Icon" src="/assets/images/icons/pay_later_icon.png" />*/}
        {/*</span>*/}
        {/*</div>*/}
      </div>
      <div className="w-[95%] h-[2px] bg-[#cbcac83c] mx-auto"></div>
      {/*<div className="flex justify-center items-center gap-[10px] mt-2">*/}
      {/*  <img alt="Share Bill" src="/assets/images/icons/people_icon.png" />*/}
      {/*  <p>Share the bill</p>*/}
      {/*</div>*/}
    </div>
  )
}

export default PaymentSelector
