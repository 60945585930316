import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const useProductsSync = (id, onComplete) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setIsLoading(true);

      const fetchProductsAndDiscounts = async () => {
        try {
          // First fetch to sync products
          const productResponse = await fetch(`${process.env.REACT_APP_API_URL}/syncProducts/${id}`);
          const productData = await productResponse.json();
          let dataWithCategories = {
            ...productData,
            Items: addCategoryToItems(productData.Items),
          };

          // Extract product IDs from the items
          const productIds = getAllProductIds(productData.Items);

          // Second fetch to get discounts
          const discountResponse = await fetch(`${process.env.REACT_APP_API_URL}/partners/getProductDiscountsBulk`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ productIds }), // Send product IDs as the request body
          });

          const discountData = await discountResponse.json();

          dataWithCategories = mapDiscountWithProduct(dataWithCategories,discountData.discounts)
          // Combine product data with discount data
          const dataWithDiscounts = {
            ...dataWithCategories,
            Discounts: discountData.discounts, // assuming 'discounts' is the key in the response
          };

          // Call onComplete callback with the combined data
          onComplete?.(dataWithDiscounts);
          setIsLoading(false);

        } catch (error) {
          console.error('Error during product sync:', error);
          navigate('/wrongGeneral');
        }
      };

      fetchProductsAndDiscounts();
    }
  }, [onComplete, id, navigate]);

  return { isLoading };
};
const addCategoryToItems = (products) => {
  products.forEach((product) => {
    function addCategory(items, categoryName) {
      items.forEach((item) => {
        item.Category = categoryName
        if (item.items && item.items.length > 0) {
          addCategory(item.items, categoryName)
        }
      })
    }

    addCategory(product.Items, product.Name)
  })

  return products
}
const getAllProductIds = (data) => {
  let prodIds = []
  data.map((eleC)=>{
    eleC.Items.map((eleP)=>{
      prodIds.push(eleP.ID)
    })
  })
  return prodIds
}

const discountPrice = (currentProduct,productDiscount) => {

  const originalPrice = currentProduct.Price ;
  let disData = productDiscount.find((ele) => ele.productId === currentProduct.ID);
  let discountPrice = 0;

  if (disData) {
    if (disData.discountedPercentage > 0) {
      discountPrice = originalPrice * (1 - disData.discountedPercentage / 100);
    } else if (disData.discountedPrice > 0) {
      discountPrice = disData.discountedPrice;
    }
    discountPrice = Math.min(discountPrice, originalPrice);
    return discountPrice;
  }else{
    return 0
  }
};


const mapDiscountWithProduct = (dataWithCategories,discountData) => {
  dataWithCategories.Items.map((eleC)=>{
    eleC.Items.map((eleP)=>{
      eleP['discountedProductPrice'] = discountPrice(eleP,discountData)
    })
  })
  return dataWithCategories
}

export default useProductsSync
