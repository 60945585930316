export const getPrice = (product) => {
  const modifierPrice = product?.modifiers
    ?.map((i) => i?.quantity ? (i?.Price || 0) * (i?.quantity || 0) : (i?.Price || 0))
    .reduce((a, b) => a + b, 0)
  return (product.item?.Price * product?.qty) + (modifierPrice ? modifierPrice : 0)
}

export const getDiscountedPrice = (product) => {
  const modifierPrice = product?.modifiers
    ?.map((i) => i?.quantity ? (i?.Price || 0) * (i?.quantity || 0) : (i?.Price || 0))
    .reduce((a, b) => a + b, 0)
  return ((product.item.discountedProductPrice?product.item.discountedProductPrice:product.item.Price) * product?.qty) + (modifierPrice ? modifierPrice : 0)
}

// todo EM add modifiers;
export const getTotalCost = (busket) => {
  let result = 0
  busket?.forEach((element) => {
    const currentPrice = getPrice(element)
    result = result + currentPrice
  })
  return result
}

export const getTotalCostLocationBased = (busket,locationId) => {
  let result = 0
  let discountedTotalPrice = 0
  let isDiscountAvailable = false
  busket?.forEach((element) => {
    if(element.locationId === locationId) {
      const currentPrice = getPrice(element)
      result = result + currentPrice

      if(element.item?.discountedProductPrice){
        isDiscountAvailable = true
      }
      const discoutedProPrice = getDiscountedPrice(element)
      discountedTotalPrice = discountedTotalPrice + discoutedProPrice;
    }
  })

  if(!isDiscountAvailable){
    discountedTotalPrice = 0;
  }

  return {result,discountedTotalPrice}
}

export const getProcessingCost = (busket) => {
  // let total = getTotalCost(busket) * 0.05
  // return total
  return 0.00
}
